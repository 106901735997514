import React, { useState } from 'react';
import T from 'i18n';
import {
  Box,
  Button,
  IconButton,
  List,
  ListItem,
  ListItemText,
  ListSubheader,
  Tooltip,
} from '@mui/material';
import LaunchIcon from '@mui/icons-material/Launch';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { FiltersStyles } from 'features/common/Filters/FiltersStyles.js';
import { useDispatch, useSelector } from 'react-redux';
import { selectCurrentFolder, selectDocuments, selectParams } from 'common/selectors';
import { Modal } from 'features/common';
import history from 'common/history';
import * as actions from 'features/case/redux/actions';

export const fileAttachmentsAction = {
  add: 'ADD',
  addAll: 'ADD ALL',
};

const AddAttachment = ({ action, show, handleClose, handleSave, file, folder }) => {
  return (
    <Modal
      show={show}
      title={T.translate('case.addAttachmentTitle')}
      handleClose={() => handleClose()}
      handleSave={() => {
        handleSave(action, file);
        handleClose();
      }}
      saveTitle={T.translate('generic.add')}
    >
      {action === fileAttachmentsAction.add
        ? T.translate('case.addAttachmentMsg', { name: file.name, folder })
        : T.translate('case.addAllAttachmentMsg', { folder })}
    </Modal>
  );
};

const FileAttachments = ({ attachments, handleClickAway, parentFileId, present }) => {
  const classes = FiltersStyles();
  const dispatch = useDispatch();
  const { file: openedFile } = useSelector(selectParams);
  const currentAllDocuments = useSelector(selectDocuments);
  const currentFolder = useSelector(selectCurrentFolder);
  const [modalAction, setModalAction] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [currentFile, setCurrentFile] = useState(null);
  const isIndexFolder = currentFolder.readOnly && currentFolder.name === 'index';

  const showAddAllBtn = () => {
    const attachmentIds = attachments.map(({ id }) => id);
    const filesInFolder = currentAllDocuments.filter(({ id }) => attachmentIds.includes(id));
    return filesInFolder.length === 0;
  };

  const handleOpenFileClicked = (doc, docExistInGrid) => {
    if (docExistInGrid) {
      history.push(
        history.location.pathname.replace(/\/files.*/, ``) +
          `/files/${docExistInGrid.id}${!!present ? `?present=${present}` : ''}`,
      );
      dispatch(actions.setCurrentSelectedFile(docExistInGrid));
    } else {
      dispatch(actions.fetchFileDetails({ fileId: doc.id })).then(res => {
        history.push(
          history.location.pathname.replace(/\/files.*/, ``) +
            `/files/${doc.id}${!!present ? `?present=${present}` : ''}`,
        );
        dispatch(actions.setCurrentSelectedFile({ ...doc, ...res }));
      });
    }
  };

  const assignAttachment = file => {
    setShowModal(true);
    setModalAction(fileAttachmentsAction.add);
    setCurrentFile(file);
  };

  const assignAllAttachments = () => {
    setShowModal(true);
    setModalAction(fileAttachmentsAction.addAll);
  };

  const handleClose = () => {
    setShowModal(false);
    setModalAction(null);
    setCurrentFile(null);
    handleClickAway();
  };

  const handleSave = (action, file) => {
    switch (action) {
      case fileAttachmentsAction.addAll:
      case fileAttachmentsAction.add:
        const currentFileIdx = currentAllDocuments.findIndex(doc => doc.id === parentFileId);
        dispatch(
          actions.assignDocuments({
            documentIds:
              action === fileAttachmentsAction.addAll
                ? attachments.map(({ id, name }) => ({ id, name }))
                : [file],
            folderId: currentFolder.id,
            isAttachment: true,
          }),
        )
          .then(() => {
            dispatch(actions.fetchFolder({ folderId: currentFolder.id, noPromise: false })).then(
              res => {
                dispatch(
                  actions.updateDocumentSort({
                    ...(action === fileAttachmentsAction.add
                      ? {
                          index: currentFileIdx + 1, //(currentFolder.zeroBasedIndex ? 1 : 2),
                          ids: [file.id],
                        }
                      : {
                          ids: [
                            ...currentAllDocuments.slice(0, currentFileIdx + 1),
                            ...attachments,
                            ...currentAllDocuments.slice(currentFileIdx + 1),
                          ],
                          isAttachments: true,
                        }),
                    zeroBasedIndex: currentFolder.zeroBasedIndex,
                    agreed: currentFolder.agreed,
                    etag: res.etag,
                  }),
                );
              },
            );
          })
          .catch(err => console.error(err));
        break;
      default:
        break;
    }
  };

  return (
    <>
      <Box>
        <List
          subheader={
            <ListSubheader
              sx={{ lineHeight: '3rem', display: 'flex', justifyContent: 'space-between' }}
            >
              {T.translate('case.fileAttachments')}
              {!isIndexFolder && attachments.length > 1 && showAddAllBtn() && (
                <Button type="text" size="small" onClick={() => assignAllAttachments()}>
                  {T.translate('generic.addAll')}
                </Button>
              )}
            </ListSubheader>
          }
          className={classes.paper}
          sx={{ width: 'max-content' }}
        >
          {attachments.map((doc, index) => {
            const docExistInGrid = currentAllDocuments.find(item => item.id === doc.id);
            return (
              <ListItem
                key={index}
                sx={{
                  pt: 0,
                  pb: 0,
                  color: 'black',
                  ...(openedFile === doc.id && { backgroundColor: 'rgba(0, 0, 0, 0.1)' }),
                }}
              >
                <ListItemText
                  sx={{ cursor: 'default' }}
                  primary={`${doc.id} - ${doc.name}${
                    docExistInGrid && docExistInGrid.tab ? ` [Tab ${docExistInGrid.tab}]` : ''
                  }`}
                />
                <div>
                  {!docExistInGrid && (
                    <Tooltip
                      title={
                        isIndexFolder
                          ? T.translate('case.addAttachmentIndexFolderTooltipMsg')
                          : T.translate('case.addAttachmentTooltipMsg')
                      }
                      placement="top"
                      arrow
                    >
                      <IconButton
                        size="small"
                        sx={{
                          ...(isIndexFolder && { cursor: 'default !important' }),
                        }}
                        onClick={e => (isIndexFolder ? null : assignAttachment(doc))}
                      >
                        <AddCircleIcon color={`${isIndexFolder ? 'default' : 'primary'}`} />
                      </IconButton>
                    </Tooltip>
                  )}
                  <Tooltip
                    title={T.translate('case.viewAttachmentTooltipMsg')}
                    placement="top"
                    arrow
                  >
                    <IconButton
                      size="small"
                      onClick={() => handleOpenFileClicked(doc, docExistInGrid)}
                    >
                      <LaunchIcon sx={{ color: 'black' }} />
                    </IconButton>
                  </Tooltip>
                </div>
              </ListItem>
            );
          })}
        </List>
      </Box>
      {showModal &&
        (modalAction === fileAttachmentsAction.add ||
          modalAction === fileAttachmentsAction.addAll) && (
          <AddAttachment
            show={showModal}
            action={modalAction}
            handleClose={handleClose}
            handleSave={handleSave}
            file={currentFile}
            folder={currentFolder.name}
          />
        )}
    </>
  );
};

export default FileAttachments;
