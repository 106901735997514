import React, { useState } from 'react';
import { Typography, Paper, Tabs, Tab, Box } from '@mui/material';
import T from 'i18n';
import { MappingProblem } from './types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimesCircle } from '@fortawesome/free-solid-svg-icons';

type MappingErrorsProps = {
  failedRows: MappingProblem[];
  showAllFailedRows: boolean;
  setShowAllFailedRows: React.Dispatch<React.SetStateAction<boolean>>;
  exclusionMessage: string;
  normalMessage: string;
};
const BADGE_HEIGHT = 16;
const MappingErrors: React.FC<MappingErrorsProps> = ({
  failedRows,
  showAllFailedRows,
  setShowAllFailedRows,
  exclusionMessage,
  normalMessage,
}) => {
  const [currentTab, setCurrentTab] = useState(0);

  if (failedRows.length === 0) return null;

  const errors = failedRows.filter(item => item.type === 'error');
  const warnings = failedRows.filter(item => item.type === 'warning');

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setCurrentTab(newValue);
  };

  const activeRows = currentTab === 0 ? errors : warnings;
  const activeMessage = currentTab === 0 ? exclusionMessage : normalMessage;

  return (
    <Paper
      elevation={0}
      style={{
        padding: '10px',
        marginTop: '10px',
        position: 'relative',
      }}
    >
      <Box sx={{ borderBottom: 1, borderColor: 'divider', marginBottom: '10px' }}>
        <Tabs
          value={currentTab}
          onChange={handleChange}
          sx={{
            '& .MuiTabs-indicator': {
              backgroundColor: '#748CEC',
            },
            '&.Mui-selected .MuiTypography-root': {
              color: '#748CEC',
            },
          }}
        >
          <Tab
            label={
              <Box display="flex" alignItems="center" gap={1}>
                <Typography variant="body2">{T.translate('case.batchUpload.errors')}</Typography>
                <Box
                  sx={{
                    backgroundColor: 'grey',
                    color: 'white',
                    borderRadius: '50%',
                    width: BADGE_HEIGHT,
                    height: BADGE_HEIGHT,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    fontSize: '0.75rem',
                  }}
                >
                  {errors.length}
                </Box>
              </Box>
            }
            value={0}
            sx={{
              '& .MuiTabs-indicator': {
                backgroundColor: '#748CEC',
              },
              '&.Mui-selected .MuiTypography-root': {
                color: '#748CEC',
              },
            }}
          />
          <Tab
            label={
              <Box display="flex" alignItems="center" gap={1}>
                <Typography variant="body2">{T.translate('case.batchUpload.warnings')}</Typography>
                <Box
                  sx={{
                    backgroundColor: 'grey',
                    color: 'white',
                    borderRadius: '50%',
                    width: BADGE_HEIGHT,
                    height: BADGE_HEIGHT,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    fontSize: '0.75rem',
                  }}
                >
                  {warnings.length}
                </Box>
              </Box>
            }
            value={1}
            sx={{
              '& .MuiTabs-indicator': {
                backgroundColor: '#748CEC',
              },
              '&.Mui-selected .MuiTypography-root': {
                color: '#748CEC',
              },
            }}
          />
        </Tabs>
      </Box>

      <Typography variant="body1" color={'textSecondary'} style={{ marginBottom: '10px' }}>
        {activeMessage}
      </Typography>

      <ul style={{ listStyleType: 'none', paddingLeft: 0 }}>
        {(showAllFailedRows ? activeRows : activeRows.slice(0, 4)).map((item, index) => (
          <li key={index}>
            {item.rowNumber !== null ? (
              <span>
                <FontAwesomeIcon
                  icon={faTimesCircle}
                  style={{
                    color: 'orange',
                    marginRight: '3px',
                  }}
                />
                <strong>
                  {T.translate('case.batchUpload.dataMapper.row', { row: item.rowNumber })}
                </strong>
                {' - '}
                {item.reason}
              </span>
            ) : (
              <span>
                <FontAwesomeIcon
                  icon={faTimesCircle}
                  style={{
                    color: 'red',
                    marginRight: '3px',
                  }}
                />
                <strong>{item.reason}</strong>
              </span>
            )}
          </li>
        ))}
      </ul>
      {activeRows.length > 4 && (
        <Typography
          variant="body2"
          style={{ cursor: 'pointer', textAlign: 'right', marginTop: '10px' }}
          onClick={() => setShowAllFailedRows(!showAllFailedRows)}
          sx={{ color: '#748CEC' }}
        >
          {showAllFailedRows
            ? T.translate('case.batchUpload.advancedSettings.seeLess')
            : T.translate('case.batchUpload.advancedSettings.seeMore')}
        </Typography>
      )}
    </Paper>
  );
};

export default MappingErrors;
