import React, { useEffect } from 'react';
import {
  IconButton,
  Box,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useDispatch } from 'react-redux';
import { setSidebarOpen } from 'features/common/redux/navbarLayout';
import { RecursiveRenderer } from 'features/case/SearchCenter/DocSummary';
import PerfectScrollbar from 'react-perfect-scrollbar';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import api from 'common/api';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { setAiToolsFilters } from 'features/case/redux/setAiToolsFilters';
import history from 'common/history';
import { useSelector } from 'react-redux';
import { selectCanBurnCrossRef, selectPermissions, selectUserAiTools } from 'common/selectors';

type SideSummaryDisplayProps = {
  open: boolean;
  onClose: () => void;
  summaries: any[];
  fileId: string;
  caseId: string;
  fileName: string;
};

const SideSummaryDisplay = ({
  open,
  onClose,
  summaries,
  fileId,
  caseId,
  fileName,
}: SideSummaryDisplayProps) => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setSidebarOpen(!open));
  }, [open, dispatch]);

  const handleAiToolsClicked = (files: any) => {
    dispatch(
      setAiToolsFilters({ files: files.map((file: any) => ({ id: file.id, name: file.name })) }),
    );
    history.push(history.location.pathname.replace(/\/case.*/, ``) + `/case/${caseId}/ai-tools`);
  };

  const deleteSummary = async () => {
    await api.del(`/cases/${caseId}/files/${fileId}/summary/${fileId}-transcript`);
  };

  const showAiTools = useSelector(selectUserAiTools);

  const canBurnCrossRef = useSelector(selectCanBurnCrossRef);

  if (!open) return null;
  return (
    <Box
      sx={{
        position: 'relative',
        top: 0,
        right: 0,
        width: '350px',
        height: '100%',
        backgroundColor: 'background.paper',
        boxShadow: 3,
        display: 'flex',
        flexDirection: 'column',
        transition: 'width 1s ease-out',
      }}
    >
      {showAiTools ? (
        <>
          <Box display="flex" justifyContent="space-between" alignItems="center" sx={{ p: 2 }}>
            <Typography variant="h6">Summaries</Typography>
            <Box>
              {canBurnCrossRef && (
                <IconButton
                  onClick={e => {
                    e.stopPropagation();
                    deleteSummary();
                  }}
                  size="small"
                  sx={{ color: 'red' }}
                >
                  <FontAwesomeIcon icon={faTrash} />
                </IconButton>
              )}

              <IconButton onClick={onClose}>
                <CloseIcon />
              </IconButton>
            </Box>
          </Box>
          {summaries?.length > 0 ? (
            <PerfectScrollbar
              style={{
                flexGrow: 1,
                padding: '0 16px 16px 16px',
                minHeight: '100px',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              {summaries.map((item, index) => (
                <Accordion
                  key={index}
                  sx={{
                    overflow: 'hidden',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      pr: 2,
                    }}
                  >
                    <Typography sx={{ fontWeight: 'bold', flexGrow: 1 }}>
                      {item.barrister?.name || item.witness?.name || `Summary ${index + 1}`}
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <RecursiveRenderer data={item} />
                  </AccordionDetails>
                </Accordion>
              ))}
            </PerfectScrollbar>
          ) : (
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                height: '100%',
              }}
            >
              <Typography
                sx={{
                  textAlign: 'center',
                }}
              >
                No summaries available.
              </Typography>
              <Typography
                sx={{
                  textDecoration: 'underline',
                  textAlign: 'center',
                  color: '#748CEC',
                }}
                onClick={() => handleAiToolsClicked([{ id: fileId, name: fileName }])}
              >
                Click to generate a summary
              </Typography>
            </div>
          )}
        </>
      ) : (
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100%',
          }}
        >
          <Typography
            sx={{
              textAlign: 'center',
            }}
          >
            You do not have access to AI Summaries
          </Typography>
        </div>
      )}
    </Box>
  );
};

export default SideSummaryDisplay;
