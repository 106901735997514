import React, { useCallback, useRef, useState } from 'react';
import { makeStyles } from '@mui/styles';
import T from 'i18n';
import validation from 'utils/validation';
import { Controller, useForm } from 'react-hook-form';
import { CUSTOM_FLAG } from 'features/common/MaterialBasedComponents/Lookup';
import clsx from 'clsx';
import { Box, Modal, Typography } from '@mui/material';
import { faTrash, faUpload } from '@fortawesome/free-solid-svg-icons';
import { CustomViewerButton, SimpleTooltip } from 'features/common';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from 'moment';
import DocumentLookup from '../../DocumentLookup';
import { Button } from 'react-bootstrap';

const useStyles = makeStyles(() => ({
  noBorder: {
    border: 'none',
  },
  uploadIcon: {
    padding: '0.2rem',
    borderRadius: '50% !important',
    fontSize: '2rem',
    color: 'white',
    margin: '0.5rem',
    cursor: 'pointer',
    backgroundColor: '#4567e5',
  },
  markButton: {
    padding: '0.25rem',
    position: 'relative',
    transition: 'opacity 150ms, visibility 0ms 150ms',
    whiteSpace: 'nowrap',
    backgroundColor: '#4567e5',
    border: 'none',
    boxShadow: '0 4px 6px 0 #666',
    color: 'white',
    textAlign: 'center',
    opacity: '0.85',
    margin: '0.1rem',
    borderRadius: '10%',
    a: {
      color: 'white',
      '&:hover': {
        textDecoration: 'underline',
        cursor: 'pointer',
      },
    },
    "&[type='button']:hover": {
      boxShadow: '0 4px 10px 0 #666',
      opacity: 1,
    },
  },
  error: {
    backgroundColor: 'red',
  },
  disabled: {
    backgroundColor: '#A5A4A1',
  },
  modal: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    background: 'white',
    borderRadius: '0.5rem',
    width: 200,
    height: 100,
    padding: '1rem',
  },
}));

interface LinkInputProps {
  onClick: (data: { link: string; linkText: string }) => void;
  update?: boolean;
  deleteAnnotation: any; // Replace 'any' with the correct type
  createdBy: any; // Replace 'any' with the correct type
  page: any; // Replace 'any' with the correct type
  link: any; // Replace 'any' with the correct type
  linkText: any; // Replace 'any' with the correct type
}

export default ({
  onClick,
  update = false,
  deleteAnnotation,
  createdBy,
  page,
  link,
  linkText,
}: LinkInputProps) => {
  // const searchText = mark && { id: mark.getData('linkId') || '', type: CUSTOM_FLAG };
  const {
    formState: { errors, isDirty },
    control,
    handleSubmit,
    watch,
    setValue,
  } = useForm({
    defaultValues: {
      link,
      linkText,
    },
  });

  const noLinkText = !watch('linkText');

  const classes = useStyles();
  const saveLink = () => !noLinkText && handleSubmit(onClick)();

  const [areYouSureDelete, setAreYouSureDelete] = useState(false);
  const [areYouSureLeave, setAreYouSureLeave] = useState<any | null>(false);
  const containerRef = useRef<HTMLDivElement>(null);

  const closePopup = useCallback(() => {
    if (page) {
      page.dispatchEvent(
        new MouseEvent('mousedown', {
          view: window,
          bubbles: true,
          cancelable: true,
        }),
      );
      page.dispatchEvent(
        new MouseEvent('mouseup', {
          view: window,
          bubbles: true,
          cancelable: true,
        }),
      );
    }
  }, [page]);

  const handleMouseDown = useCallback(
    (event: any) => {
      if ((update && isDirty) || areYouSureDelete) {
        !areYouSureDelete && isDirty && setAreYouSureLeave(event.target);
        event.stopPropagation();
        event.preventDefault();
        return false;
      } else {
        closePopup();
        document.removeEventListener('mousedown', handleMouseDown, true);
      }
    },
    [isDirty, update, closePopup, areYouSureDelete],
  );

  return (
    <div style={{ width: '100%', height: '100%' }}>
      <div
        style={{ position: 'fixed', bottom: 0, top: 0, right: 0, left: 0, zIndex: -1 }}
        onClick={handleMouseDown}
      />
      <div ref={containerRef} style={{ width: '100%', height: '100%', zIndex: 1 }}>
        <Modal
          open={areYouSureDelete || !!areYouSureLeave}
          container={containerRef.current}
          sx={{ position: 'absolute', '& .MuiModal-backdrop': { position: 'absolute' } }}
        >
          <Box className={classes.modal}>
            <Typography
              id="modal-modal-title"
              variant="h6"
              component="h2"
              style={{
                textAlign: 'center',
              }}
            >
              {areYouSureDelete ? 'Delete. Are you sure?' : 'Leave without saving?'}
            </Typography>

            <div
              style={{ width: '100%', padding: '1rem', display: 'flex', justifyContent: 'center' }}
            >
              <Button
                style={{ margin: '0.2rem', background: '#4567e5', color: 'white' }}
                onClick={() => {
                  if (areYouSureDelete) deleteAnnotation();
                  else {
                    closePopup();
                    document.removeEventListener('mousedown', handleMouseDown, true);
                  }
                }}
              >
                {T.translate('generic.yes')}
              </Button>
              <Button
                variant="outlined"
                onClick={() => {
                  if (areYouSureDelete) setAreYouSureDelete(false);
                  else setAreYouSureLeave(null);
                }}
                style={{ margin: '0.2rem', borderColor: '#4567e5' }}
              >
                {T.translate('generic.no')}
              </Button>
            </div>
          </Box>
        </Modal>
        <div
          style={{
            display: 'flex',
            flexDirection: update ? 'column' : 'row',
            ...(update && { padding: '1rem' }),
          }}
        >
          <div style={{ position: 'absolute', right: '0.5rem', top: '0.5rem' }}>
            {update && (
              <CustomViewerButton
                onClick={saveLink}
                className={clsx(classes.markButton, {
                  [classes.disabled]: noLinkText,
                })}
              >
                <FontAwesomeIcon style={{ margin: '0 0.25rem' }} icon={faUpload} />
              </CustomViewerButton>
            )}
            {update && (
              <CustomViewerButton
                onClick={() => setAreYouSureDelete(true)}
                className={classes.markButton}
              >
                <FontAwesomeIcon style={{ margin: '0 0.25rem' }} icon={faTrash} />
              </CustomViewerButton>
            )}
          </div>
          {update && <Typography variant="h6">{createdBy?.name}</Typography>}
          {update && (
            <Typography style={{ color: 'gray' }} variant="subtitle2">
              {`Created on ${moment(createdBy?.date)
                .utc()
                .format('DD MMM HH:mm')}`}
            </Typography>
          )}
          {update && (
            <Typography style={{ marginTop: '1rem' }} variant="h6" component="h6">
              {'Comment'}
            </Typography>
          )}

          <Controller
            name="link"
            control={control}
            rules={{
              required: true,
              validate: (item: any) => {
                if (!item || !item.id) return 'required';
                if (item.type === CUSTOM_FLAG && !new RegExp(validation.link).test(item.id))
                  return 'invalid link';
                return true;
              },
            }}
            render={({ field }: any) => {
              return (
                <DocumentLookup
                  {...field}
                  autoFocus
                  warning={'This link will be visible to everyone!'}
                  sx={{ padding: '9px 0px', flexGrow: 1 }}
                  placeholder={T.translate('case.linkPlaceholder')}
                  onCustomSelect={(val: any) => {
                    if (val && !val.type) {
                      setValue('linkText', `${val.id} (${val.name})`);
                    } else {
                      setValue('linkText', val ? val.id : '');
                    }
                  }}
                  {...(update && {
                    error: errors.link,
                    helperText: errors.link && errors.link.message,
                  })}
                />
              );
            }}

            // defaultValue={searchText}
          />
          {!update && (
            <SimpleTooltip title={errors.link?.message}>
              <CustomViewerButton
                onClick={saveLink}
                className={clsx(classes.markButton, {
                  [classes.error]: !!errors.link,
                  [classes.disabled]: noLinkText,
                })}
                style={{
                  margin: '0.5rem',
                  width: '2.5rem',
                  borderRadius: '10%',
                  height: '2.5rem',
                  alignSelf: 'center',
                }}
              >
                <FontAwesomeIcon style={{ margin: '0 0.25rem' }} icon={faUpload} />
              </CustomViewerButton>
            </SimpleTooltip>
          )}
        </div>
      </div>
    </div>
  );
};
