import React, { useRef, useEffect, useState, useCallback } from 'react';
import MarkButton from './MarkButton';
import MarkTooltip from './MarkTooltip';
import html2canvas from 'html2canvas';
import { ViewerControl, Mark } from '@prizmdoc/viewer-core';
import { convert, cropCanvas, getScrollContainer } from '../utils';
import { CUSTOM_FLAG } from 'features/common/MaterialBasedComponents/Lookup';
import { useSelector } from 'react-redux';
import {
  selectCrossRef,
  selectCrossRefFontSize,
  selectCurrentCaseId,
  selectCurrentFile,
  selectCurrentSelectedFileMetaData,
  selectPermissions,
  selectPublicHyperlinks,
  selectQuery,
} from 'common/selectors';
import { LINK_COLOR, REGULAR_COLOR, SHARED_COLOR } from '../constants';
import { useAddAnnotation } from 'features/viewing/redux/addAnnotation';
import { useUpdateAnnotation } from 'features/viewing/redux/updateAnnotation';
import { useDeleteAnnotation } from 'features/viewing/redux/deleteAnnotation';

type NotionInterfaceProps = {
  viewerControl: typeof ViewerControl;
  isPresenting: boolean;
  markToBeCreated: typeof Mark;
  markToBeUpdated: typeof Mark;
  highlights: Array<any>;
  isTheatreMode: boolean;
  fileId?: string;
  deleteMarkToBeCreated: (mark?: typeof Mark) => void;
  setMarkToBeUpdated: (mark: typeof Mark) => void;
  setMarkToBeCreated: (mark: typeof Mark) => void;
  hideTimeoutRef: React.MutableRefObject<any>;
  pauseCreateMarkHandlerRef: React.MutableRefObject<boolean>;
  userId: string;
};

export default ({
  viewerControl,
  isPresenting,
  markToBeCreated,
  markToBeUpdated,
  highlights,
  isTheatreMode,
  fileId,
  deleteMarkToBeCreated,
  setMarkToBeUpdated,
  hideTimeoutRef,
  pauseCreateMarkHandlerRef,
  userId,
}: NotionInterfaceProps) => {
  const currentCaseId = useSelector(selectCurrentCaseId);
  const currentFileMetaData = useSelector(selectCurrentSelectedFileMetaData) as any;
  const queryParam = useSelector(selectQuery);
  const crossRefsEnabled = useSelector(selectCrossRef);
  const permissions = useSelector(selectPermissions);
  const currentFile = useSelector(selectCurrentFile);
  const publicHyperlinksEnabled = useSelector(selectPublicHyperlinks);
  const crossRefFontSize = useSelector(selectCrossRefFontSize);
  const [markHoveredOver, setMarkHoveredOver] = useState<any | null>(null);

  const [textSelection, setTextSelection] = useState<any | null>(null);
  const [tooltipPoint, setTooltipPoint] = useState<{ x: number; y: number }>({ x: 0, y: 0 });

  const previousPointRef = useRef<any>(null);
  const currentPointRef = useRef<any>(null);
  const timeoutIdRef = useRef<any>(null);

  const { addAnnotation: addAnnotationApi } = useAddAnnotation();
  const { updateAnnotation: updateAnnotationApi } = useUpdateAnnotation();
  const { deleteAnnotation: deleteAnnotationApi } = useDeleteAnnotation();

  const {
    documents: { canGeneratePublicHyperlinks, canCreateCrossRef },
  } = permissions;
  const canCreateAndEditCrossRefs = crossRefsEnabled && canCreateCrossRef(currentFile);
  const canCreateAndEditPublicHyperlinks =
    publicHyperlinksEnabled && canGeneratePublicHyperlinks(currentFile);
  const scrollContainer = getScrollContainer(viewerControl);

  //TEXT HIGHLIGHT
  const addHighlightHandler = (props: any) => {
    const { comment, tags } = props || {};

    try {
      pauseCreateMarkHandlerRef.current = true;

      const highLightMark = viewerControl.addMark(
        markToBeCreated.pageNumber,
        Mark.Type.HighlightAnnotation,
      );
      highLightMark.setPosition(markToBeCreated.position);
      highLightMark.setFillColor(REGULAR_COLOR);
      highLightMark.setData('comment', comment);
      highLightMark.interactionMode = Mark.InteractionMode.SelectionDisabled;
      highLightMark.opacity = 150;

      addAnnotationHandler(highLightMark, tags);
    } finally {
      setTimeout(() => (pauseCreateMarkHandlerRef.current = false), 250); // needed for acusoft to stop triggering events
    }
  };

  const addRedactionHandler = ({ reason }: any = {}) => {
    try {
      pauseCreateMarkHandlerRef.current = true;

      const highLightMark = viewerControl.addMark(
        markToBeCreated.pageNumber,
        Mark.Type.TextSelectionRedaction,
      );
      highLightMark.setPosition(markToBeCreated.position);
      highLightMark.setData('reason', reason);
      highLightMark.setReason(reason);
      highLightMark.interactionMode = Mark.InteractionMode.SelectionDisabled;

      addAnnotationHandler(highLightMark);
    } finally {
      setTimeout(() => (pauseCreateMarkHandlerRef.current = false), 250); // needed for acusoft to stop triggering events
    }
  };

  const updateHighlightHandler = ({ comment, tags }: any) => {
    const annotation = highlights.find((x: any) => x.annotation.uid === markToBeUpdated.uid);
    markToBeUpdated.setFillColor(
      annotation && annotation.createdBy && annotation.createdBy.id !== userId
        ? SHARED_COLOR
        : REGULAR_COLOR,
    );
    markToBeUpdated.setData('comment', comment);

    if (annotation) {
      viewerControl.serializeMarks([markToBeUpdated]).then(([markObject]: any) => {
        if (markToBeUpdated) setMarkToBeUpdated(undefined);
        updateAnnotationHandler(
          { ...annotation.annotation, ...markObject },
          annotation.id,
          tags,
          annotation.shared,
        );
      });
    }
  };

  const updateRedactionHandler = ({ reason }: any) => {
    const annotation = highlights.find((x: any) => x.annotation.uid === markToBeUpdated.uid);
    markToBeUpdated.setData('reason', reason);
    markToBeUpdated.setReason(reason);

    if (annotation) {
      viewerControl.serializeMarks([markToBeUpdated]).then(([markObject]: any) => {
        if (markToBeUpdated) setMarkToBeUpdated(undefined);
        updateAnnotationHandler({ ...annotation.annotation, ...markObject }, annotation.id);
      });
    }
  };

  const addLinkHandler = ({ link, linkText }: any) => {
    try {
      pauseCreateMarkHandlerRef.current = true;

      const linkMark = viewerControl.addMark(
        markToBeCreated.pageNumber,
        Mark.Type.TextHyperlinkAnnotation,
      );
      linkMark.setPosition(markToBeCreated.position);
      linkMark.setFillColor(LINK_COLOR);
      linkMark.setData('link', JSON.stringify(link));
      linkMark.setData('linkId', link.id);
      linkMark.setData('linkName', link.name);
      linkMark.setData('linkType', link.type);
      linkMark.setData('linkText', linkText);
      linkMark.interactionMode = Mark.InteractionMode.SelectionDisabled;
      linkMark.opacity = 150;
      linkMark.href = getHrefFromLink(link);
      addAnnotationHandler(linkMark);
    } finally {
      setTimeout(() => (pauseCreateMarkHandlerRef.current = false), 250); // needed for acusoft to stop triggering events
    }
  };

  const updateLinkHandler = ({ link, linkText, tags }: any) => {
    markToBeUpdated.setFillColor(LINK_COLOR);
    markToBeUpdated.setData('link', JSON.stringify(link));
    markToBeUpdated.setData('linkId', link.id);
    markToBeUpdated.setData('linkName', link.name);
    markToBeUpdated.setData('linkType', link.type);
    markToBeUpdated.setData('linkText', linkText);
    markToBeUpdated.href = getHrefFromLink(link);

    const annotation = highlights.find((x: any) => x.annotation.uid === markToBeUpdated.uid);

    if (annotation) {
      viewerControl.serializeMarks([markToBeUpdated]).then(([markObject]: any) => {
        if (markToBeUpdated) setMarkToBeUpdated(undefined);
        updateAnnotationHandler(
          { ...annotation.annotation, ...markObject },
          annotation.id,
          tags,
          annotation.shared,
        );
      });
    }
  };

  // RECTANGLE HIGHLIGHT
  const addRectangleHighlightHandler = ({ comment, tags }: any = {}) => {
    try {
      pauseCreateMarkHandlerRef.current = true;

      const rectangleMark = viewerControl.addMark(
        markToBeCreated.pageNumber,
        Mark.Type.RectangleAnnotation,
      );

      rectangleMark.setRectangle(markToBeCreated.rectangle);
      rectangleMark.setFillColor(REGULAR_COLOR);
      rectangleMark.setData('comment', comment);
      rectangleMark.opacity = 150;
      rectangleMark.interactionMode = Mark.InteractionMode.SelectionDisabled;
      rectangleMark.borderThickness = 1;
      rectangleMark.borderColor = '#000000';

      addAnnotationHandler(rectangleMark, tags);
    } finally {
      setTimeout(() => (pauseCreateMarkHandlerRef.current = false), 250); // needed for acusoft to stop triggering events
    }
  };

  const addRectangleRedactionHandler = ({ reason }: any = {}) => {
    try {
      pauseCreateMarkHandlerRef.current = true;

      const rectangleMark = viewerControl.addMark(
        markToBeCreated.pageNumber,
        Mark.Type.RectangleRedaction,
      );

      rectangleMark.setRectangle(markToBeCreated.rectangle);
      rectangleMark.setData('reason', reason);
      rectangleMark.setReason(reason);
      rectangleMark.interactionMode = Mark.InteractionMode.SelectionDisabled;

      addAnnotationHandler(rectangleMark);
    } finally {
      setTimeout(() => (pauseCreateMarkHandlerRef.current = false), 250); // needed for acusoft to stop triggering events
    }
  };

  const addCrossReferenceHandler = ({ link, linkText, crossRef }: any) => {
    try {
      pauseCreateMarkHandlerRef.current = true;

      const crossRefMark = viewerControl.addMark(
        markToBeCreated.pageNumber,
        Mark.Type.TextAnnotation,
      );
      crossRefMark.setRectangle(markToBeCreated.rectangle);
      crossRefMark.setFontSize(crossRefFontSize);
      crossRefMark.setText(crossRef);
      crossRefMark.setBorderColor('#FFFFFF');
      crossRefMark.setData('link', JSON.stringify(link));
      crossRefMark.setData('linkId', link.id);
      crossRefMark.setData('linkName', link.name);
      crossRefMark.setData('linkType', link.type);
      crossRefMark.setData('linkText', linkText);
      crossRefMark.setData('crossRef', crossRef);
      crossRefMark.setData('href', getHrefFromLink(link));
      crossRefMark.interactionMode = Mark.InteractionMode.SelectionDisabled;

      addAnnotationHandler(crossRefMark);
    } finally {
      setTimeout(() => (pauseCreateMarkHandlerRef.current = false), 250); // needed for acusoft to stop triggering events
    }
  };

  const updateCrossRefferenceHandler = ({ link, linkText, crossRef }: any) => {
    markToBeUpdated.setFillColor('transparent');
    markToBeUpdated.setText(crossRef);
    markToBeUpdated.setData('link', JSON.stringify(link));
    markToBeUpdated.setData('linkId', link.id);
    markToBeUpdated.setData('linkName', link.name);
    markToBeUpdated.setData('linkType', link.type);
    markToBeUpdated.setData('linkText', linkText);
    markToBeUpdated.setData('crossRef', crossRef);
    markToBeUpdated.setData('href', getHrefFromLink(link));

    const annotation = highlights.find((x: any) => x.annotation.uid === markToBeUpdated.uid);

    if (annotation) {
      viewerControl.serializeMarks([markToBeUpdated]).then(([markObject]: any) => {
        if (markToBeUpdated) setMarkToBeUpdated(undefined);
        updateAnnotationHandler({ ...annotation.annotation, ...markObject }, annotation.id);
      });
    }
  };

  const copyTextHandler = () => {
    if (textSelection) {
      navigator.clipboard.writeText(textSelection);
      setTextSelection(null);
      deleteMarkToBeCreated();
    }
  };

  const addEventHandler = () => deleteMarkToBeCreated();

  const getHrefFromLink = (link: any) => {
    const goToPage = link && link.page && parseInt(link.page);
    return (
      link &&
      (link.type === CUSTOM_FLAG
        ? link.id.includes('http')
          ? link.id
          : `//${link.id}`
        : `/case/${currentCaseId}/files/${link.id}${goToPage ? `?goToPage=${goToPage}` : ''}`)
    );
  };

  const updateAnnotationHandler = useCallback(
    (
      annotation: any,
      id: string,
      tags?: Array<any>,
      shared?: boolean,
      updateImageOnly: boolean = false,
    ) => {
      return updateAnnotationApi({
        annotation,
        tags,
        id,
        shared,
        fileId,
        ...((currentFileMetaData &&
          currentFileMetaData.folderId && {
            folderId: currentFileMetaData.folderId,
          }) ||
          (queryParam &&
            queryParam.folderId && {
              folderId: queryParam.folderId,
            })),
        updateImageOnly,
      });
    },
    [currentFileMetaData, fileId, queryParam, updateAnnotationApi],
  );

  const updateImageUnderAnnotation = useCallback(
    ({ annotation, id, tags, shared }: any, mark: any) => {
      if (annotation && mark.type === Mark.Type.RectangleAnnotation) {
        const { clientX, clientY, clientWidth, clientHeight } = convert(mark, viewerControl);
        html2canvas(document.body, {
          x: clientX,
          y: clientY,
          imageTimeout: 0,
          ignoreElements: elem =>
            (elem.className && elem.className.toString().includes('MuiPaper-root')) ||
            elem.nodeName === 'rect',
          allowTaint: true,
          useCORS: true,
        }).then(canvas => {
          const newCanvas = cropCanvas(canvas, clientWidth, clientHeight);
          if (mark) mark.setData('image', newCanvas.toDataURL());
          if (annotation)
            updateAnnotationHandler(
              {
                ...annotation,
                data: {
                  ...annotation.data,
                  image: newCanvas.toDataURL(),
                },
              },
              id,
              tags,
              shared,
              true,
            );
        });
      }
    },
    [updateAnnotationHandler, viewerControl],
  );

  const addAnnotationHandler = useCallback(
    (mark: any, tags?: any) => {
      if (!mark) return;
      viewerControl.serializeMarks([mark]).then(([markObject, ..._]: any) => {
        addAnnotationApi({
          annotation: markObject,
          tags,
          ...(isTheatreMode && { fileId }),
          ...((currentFileMetaData?.folderId && {
            folderId: currentFileMetaData.folderId,
          }) ||
            (queryParam?.folderId && {
              folderId: queryParam.folderId,
            })),
        }).then((annotation: any) => {
          updateImageUnderAnnotation(annotation, mark);
        });
        deleteMarkToBeCreated(mark);
      });
    },
    [
      addAnnotationApi,
      currentFileMetaData.folderId,
      deleteMarkToBeCreated,
      fileId,
      isTheatreMode,
      queryParam.folderId,
      updateImageUnderAnnotation,
      viewerControl,
    ],
  );

  const deleteAnnotationHandler = (mark: any) => {
    const annotation = highlights.find((x: any) => x.annotation.uid === mark.uid);
    deleteAnnotationApi({ annotation, fileId }).then(() => {
      viewerControl.deleteMarks([mark]);
    });
  };

  const textSelectedHandler = ({ selectedText: textSel }: any) => {
    setTextSelection(textSel);
  };

  const updateMousePosition = ({ mark, clientX, clientY }: any) => {
    currentPointRef.current = { x: clientX, y: clientY };
    // const { clientWidth, clientX, clientY } = convert(mark, viewerControl);
    // const x = clientX + clientWidth / 2 + clientWidth; // add client width so it's similar to mark button
    // const y = clientY - 5;
    // currentPoint = { x, y };
  };

  const clearTooltipTimeout = () => {
    if (timeoutIdRef.current) {
      timeoutIdRef.current = clearTimeout(timeoutIdRef.current);
    }
  };

  const showTooltipHandler = useCallback(
    (mark: typeof Mark) => {
      if (mark.type !== Mark.Type.TextHyperlinkAnnotation) return; // show only for links
      if (markToBeCreated || markToBeUpdated) return; // don't allow show tooltip if mark button is active
      if (hideTimeoutRef.current) hideTimeoutRef.current = clearTimeout(hideTimeoutRef.current);
      if (tooltipPoint !== currentPointRef.current || markHoveredOver !== mark) {
        setTooltipPoint(currentPointRef.current);
        setMarkHoveredOver(mark);
      }
    },
    [
      hideTimeoutRef,
      markHoveredOver,
      markToBeCreated,
      markToBeUpdated,
      setMarkHoveredOver,
      tooltipPoint,
    ],
  );

  const markMouseEnterHandler = useCallback(
    (eventArgs: any) => {
      if (isPresenting) return;

      if (eventArgs.mark && eventArgs.mark.type === Mark.Type.TextHyperlinkAnnotation) {
        (document.querySelector(`[data-pcc-mark='mark-${eventArgs.mark.id}']`) as any).style =
          'cursor: pointer';
      }

      const compareMousePoints = (eArgs: any) => {
        // distance to travel in px
        const travelDistance = 6;
        const { x: prevX, y: prevY } = previousPointRef.current || {};
        const { x, y } = currentPointRef.current || {};
        // Check if the mouse has slowed down enough
        if (Math.sqrt((prevX - x) * (prevX - x) + (prevY - y) * (prevY - y)) < travelDistance) {
          viewerControl.off('MarkMouseOver', updateMousePosition);
          // Clear old data
          timeoutIdRef.current = previousPointRef.current = null;
          showTooltipHandler(eArgs.mark);
        } else {
          // Update the previous mouse position for the next comparison
          previousPointRef.current = currentPointRef.current;
          timeoutIdRef.current = setTimeout(() => {
            compareMousePoints(eArgs);
          }, 250);
        }
      };

      if (eventArgs.mark === markToBeCreated) return;
      // if they have something to show
      if (!(eventArgs.mark.getData('comment') || eventArgs.mark.getData('linkId'))) return;
      if (hideTimeoutRef.current && markHoveredOver === eventArgs.mark) {
        hideTimeoutRef.current = clearTimeout(hideTimeoutRef.current);
        return;
      }

      // Clear existing timeouts
      clearTooltipTimeout();
      // Clone event so it can be used in IE asynchronously
      var ev = Object.assign(eventArgs);
      // Set previous X and Y position based on initial entry point
      previousPointRef.current = { x: ev.clientX, y: ev.clientY };
      // Update current X and Y every time the mouse moves
      viewerControl.on('MarkMouseOver', updateMousePosition);
      timeoutIdRef.current = setTimeout(() => {
        compareMousePoints(ev);
      }, 250);
    },
    [
      hideTimeoutRef,
      isPresenting,
      markHoveredOver,
      markToBeCreated,
      showTooltipHandler,
      viewerControl,
    ],
  );

  const hideTooltipHandler = useCallback(
    (delay = 0) => {
      hideTimeoutRef.current = setTimeout(() => {
        markHoveredOver && setMarkHoveredOver(null);
      }, delay);
    },
    [hideTimeoutRef, markHoveredOver, setMarkHoveredOver],
  );

  const markMouseLeaveHandler = useCallback(
    (eventArgs: any) => {
      // Clear existing timeouts
      clearTooltipTimeout();

      if (!markHoveredOver) {
        return;
      }

      // Remove the expensive event listener
      viewerControl.off('MarkMouseOver', updateMousePosition);
      hideTooltipHandler(500);
    },
    [hideTooltipHandler, markHoveredOver, viewerControl],
  );

  useEffect(() => {
    viewerControl.on('TextSelected', textSelectedHandler);
    viewerControl.on('MarkMouseEnter', markMouseEnterHandler);
    viewerControl.on('MarkMouseLeave', markMouseLeaveHandler);
    viewerControl.on('MarkChanged', hideTooltipHandler);
    viewerControl.on('MarkRemoved', hideTooltipHandler);
    scrollContainer.addEventListener('scroll', hideTooltipHandler);

    return () => {
      viewerControl.off('TextSelected', textSelectedHandler);
      viewerControl.off('MarkMouseEnter', markMouseEnterHandler);
      viewerControl.off('MarkMouseLeave', markMouseLeaveHandler);
      viewerControl.off('MarkChanged', hideTooltipHandler);
      viewerControl.off('MarkRemoved', hideTooltipHandler);
      scrollContainer.removeEventListener('scroll', hideTooltipHandler);
    };
  }, [
    hideTooltipHandler,
    markMouseEnterHandler,
    markMouseLeaveHandler,
    scrollContainer,
    viewerControl,
  ]);

  // console.log('NotationInterface', 'render');

  return (
    <>
      <MarkButton
        addHighlight={addHighlightHandler}
        addRedaction={addRedactionHandler}
        addLink={addLinkHandler}
        copyText={copyTextHandler}
        addRectangleHighlight={addRectangleHighlightHandler}
        addRectangleRedaction={addRectangleRedactionHandler}
        addCrossReference={addCrossReferenceHandler}
        updateAnnotation={updateAnnotationHandler}
        markToBeCreated={markToBeCreated}
        markToBeUpdated={markToBeUpdated}
        viewerControl={viewerControl}
        scrollContainer={scrollContainer}
        highlights={highlights}
        updateHighlight={updateHighlightHandler}
        updateRedaction={updateRedactionHandler}
        updateCrossRefference={updateCrossRefferenceHandler}
        updateLink={updateLinkHandler}
        deleteAnnotation={deleteAnnotationHandler}
        canCreateAndEditCrossRefs={canCreateAndEditCrossRefs}
        canCreateAndEditPublicHyperlinks={canCreateAndEditPublicHyperlinks}
        addEventHandler={addEventHandler}
      />

      <MarkTooltip
        markHoveredOver={markHoveredOver}
        tooltipPoint={tooltipPoint}
        showTooltip={showTooltipHandler}
        hideTooltip={hideTooltipHandler}
        canCreateAndEditPublicHyperlinks={canCreateAndEditPublicHyperlinks}
        setMarkToBeUpdated={setMarkToBeUpdated}
      />
    </>
  );
};
