// Initial state is the place you define all initial values for the Redux store of the feature.
// In the 'standard' way, initialState is defined in reducers: http://redux.js.org/docs/basics/Reducers.html
// But when application grows, there will be multiple reducers files, it's not intuitive what data is managed by the whole store.
// So Rekit extracts the initial state definition into a separate module so that you can have
// a quick view about what data is used for the feature, at any time.

// NOTE: initialState constant is necessary so that Rekit could auto add initial state when creating async actions.

const initialState = {
  notifications: [],
  users: [],
  roles: [],
  hearingRoomRole: null,
  dataRoomRole: null,
  permissions: {},
  fetchAllPermissionsPending: false,
  fetchAllPermissionsFeedback: null,
  fetchNotificationsPending: false,
  fetchNotificationsFeedback: null,
  transientNotifications: [],
  fetchUsersPending: false,
  fetchUsersFeedback: null,
  fetchRolesPending: false,
  fetchRolesFeedback: null,
  updateUserSettingsPending: false,
  updateUserSettingsError: null,
  fetchUserSettingsPending: false,
  fetchUserSettingsError: null,
  fetchUserDetailsPending: false,
  fetchUserDetailsError: null,
  fetchSystemStatusPending: false,
  fetchSystemStatusError: null,
  fetchRolesByTypePending: false,
  fetchRolesByTypeFeedback: null,
  userSettings: {},
  bookmarks: [],
  isSidebarOpen: true,
};

export default initialState;
