import React, { useEffect } from 'react';
import T from 'i18n';
import { Button, Divider, Typography } from '@mui/material';
import { SelectForm, Spinner, TextForm, ToggleForm } from 'features/common';
import { useUpdateUserSettings } from 'features/common/redux/updateUserSettings';
import { useForm } from 'react-hook-form';
import { Form } from 'react-bootstrap';
import { useFetchCaseDetails } from '../redux/fetchCaseDetails';
import { Modal } from 'features/common/MaterialBasedComponents';

interface SearchOptionsProps {
  open: boolean;
  anchorEl: any;
  handleClose: any;
}

const SearchOptions = ({ open, anchorEl, handleClose }: SearchOptionsProps) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    reset,
  } = useForm();
  const { caseDetails } = useFetchCaseDetails();
  const { userSettings, updateUserSettings, updateUserSettingsPending } = useUpdateUserSettings();
  const { matches, indices, answers } = caseDetails.aiSettings || {};
  const indicesOptions = indices?.map((item: any) => ({
    label: item.name,
    value: item.name,
  }));
  const {
    index = indicesOptions[0].value,
    multiQuery = matches.multiQuery,
    maxMarginalRelevance = matches.maxMarginalRelevance,
    numMatches = matches.numMatches,
    wrapMatchesWithContext = answers.wrapMatchesWithContext || false,
    useDefaultPrompt = answers.useDefaultPrompt || true,
    systemPrompt = answers.systemPrompt || '',
    sourcePrompt = answers.sourcePrompt || '',
    useChunkUpStrategy = answers.useChunkUpStrategy || false,
    chunkUpWhenLessThanMatches = answers.chunkUpWhenLessThanMatches || 10,
    chunkEitherSideOfMatch = answers.chunkEitherSideOfMatch || 1,
    suggestedQuestions = answers.suggestedQuestions || true,
    queryRouter = answers.queryRouter || false,
  } = userSettings.aiSettings || {};
  const defaultOptions = {
    multiQuery: matches.multiQuery,
    maxMarginalRelevance: matches.maxMarginalRelevance,
    numMatches: matches.numMatches,
    index: indicesOptions[0].value,
    wrapMatchesWithContext: answers.wrapMatchesWithContext || false,
    useDefaultPrompt: answers.useDefaultPrompt || true,
    systemPrompt: answers.systemPrompt || '',
    sourcePrompt: answers.sourcePrompt || '',
    useChunkUpStrategy: answers.useChunkUpStrategy || false,
    chunkUpWhenLessThanMatches: answers.chunkUpWhenLessThanMatches || 10,
    chunkEitherSideOfMatch: answers.chunkEitherSideOfMatch || 1,
    suggestedQuestions: answers.suggestedQuestions || true,
    queryRouter: answers.queryRouter || false,
  };
  const [multiQueryValue, setMultiQueryValue] = React.useState<boolean>(multiQuery);
  const [maxMarginalRelevanceValue, setMaxMarginalRelevanceValue] = React.useState<boolean>(
    maxMarginalRelevance,
  );
  const [loading, setLoading] = React.useState<boolean>(false);
  const [useDefaultPromptVal, setUseDefaultPromptVal] = React.useState<boolean>(useDefaultPrompt);
  const [useChunkUpStrategyVal, setUseChunkUpStrategyVal] = React.useState<boolean>(
    useChunkUpStrategy,
  );
  const [resetClicked, setResetClicked] = React.useState<boolean>(false);

  useEffect(() => {
    if (!resetClicked) {
      reset(formValues => ({
        ...formValues,
        multiQuery: multiQueryValue,
        maxMarginalRelevance: maxMarginalRelevanceValue,
      }));
      setLoading(false);
    }
  }, [multiQueryValue, maxMarginalRelevanceValue, reset, resetClicked]);

  return (
    <Modal
      show={open}
      title={T.translate('case.searchOptions')}
      handleClose={() => {
        handleClose();
      }}
      handleSave={handleSubmit((data, e) => {
        e?.preventDefault();
        e?.stopPropagation();
        updateUserSettings({
          userSettings: {
            ...userSettings,
            aiSettings: {
              ...userSettings.aiSettings,
              ...data,
            },
          },
        });
        handleClose();
      })}
      disableSave={updateUserSettingsPending}
      saveTitle={T.translate('generic.save')}
      buttons={
        <>
          <Button
            variant="contained"
            type="button"
            color="primary"
            disabled={updateUserSettingsPending}
            onClick={e => {
              e.preventDefault();
              e.stopPropagation();
              setLoading(true);
              setResetClicked(true);
              setUseDefaultPromptVal(true);
              setMaxMarginalRelevanceValue(false);
              setMultiQueryValue(false);
              reset(defaultOptions);
              updateUserSettings({
                userSettings: {
                  ...userSettings,
                  aiSettings: {
                    ...userSettings.aiSettings,
                    ...defaultOptions,
                  },
                },
              }).then(() => {
                setLoading(false);
                setResetClicked(false);
              });
            }}
          >
            {T.translate('generic.reset')}
          </Button>
        </>
      }
    >
      <div style={{ minWidth: '25rem' }}>
        <Form
          onSubmit={e => {
            e.preventDefault();
            e.stopPropagation();
          }}
          /*@ts-ignore*/
        >
          <Form.Group>
            {loading ? (
              <div style={{ marginBottom: '1rem' }}>
                <Spinner />
              </div>
            ) : (
              <>
                <Typography variant="h6">{T.translate('case.optionsForMatches')}</Typography>
                <Divider sx={{ my: '0.5rem' }} />
                <div style={{ marginBottom: '1rem', width: '50%' }}>
                  <ToggleForm
                    {...register('multiQuery', {
                      onChange: e => {
                        setMultiQueryValue(!multiQueryValue);
                        if (e.target.checked && maxMarginalRelevanceValue) {
                          setMaxMarginalRelevanceValue(false);
                        }
                        setLoading(true);
                      },
                    })}
                    /*@ts-ignore*/
                    defaultValue={multiQueryValue}
                    label={T.translate('case.multiQuery')}
                    errors={errors}
                  />
                </div>
                <div style={{ marginBottom: '1rem', width: '50%' }}>
                  <ToggleForm
                    {...register('maxMarginalRelevance', {
                      onChange: e => {
                        setMaxMarginalRelevanceValue(!maxMarginalRelevanceValue);
                        if (e.target.checked && multiQueryValue) {
                          setMultiQueryValue(false);
                        }
                        setLoading(true);
                      },
                    })}
                    /*@ts-ignore*/
                    defaultValue={maxMarginalRelevanceValue}
                    label={T.translate('case.maxMarginalRelevance')}
                    errors={errors}
                  />
                </div>
                <div style={{ display: 'flex', flexDirection: 'row', marginBottom: '1rem' }}>
                  <div style={{ width: '50%' }}>
                    <TextForm
                      type="number"
                      {...register('numMatches', { required: true })}
                      /*@ts-ignore*/
                      label={T.translate('case.maximumNumberofMatches')}
                      placeholder={T.translate('case.maximumNumberofMatchesPlaceholder')}
                      defaultValue={numMatches}
                      errors={errors}
                      autofocus
                    />
                  </div>
                  <div style={{ width: '50%', marginLeft: '1rem' }}>
                    <SelectForm
                      onChange={null}
                      errors={errors}
                      placeholder={'Test'}
                      readOnly={false}
                      autofocus={false}
                      loadOptions={undefined}
                      rules={null}
                      getOptionLabel={undefined}
                      defaultOptions={undefined}
                      isMulti={false}
                      customOption={undefined}
                      noIndicator={undefined}
                      menuPosition={'fixed'}
                      name={'index'}
                      label={T.translate('generic.index')}
                      defaultValue={index}
                      options={indicesOptions}
                      control={control}
                    />
                  </div>
                </div>
                <Typography variant="h6">{T.translate('case.optionsForQueAns')}</Typography>
                <Divider sx={{ my: '0.5rem' }} />
                <div style={{ marginBottom: '1rem', width: '50%' }}>
                  <ToggleForm
                    {...register('wrapMatchesWithContext')}
                    /*@ts-ignore*/
                    defaultValue={wrapMatchesWithContext}
                    label={T.translate('case.wrapMatchesWithContext')}
                    errors={errors}
                  />
                </div>
                <div style={{ marginBottom: '1rem', width: '50%' }}>
                  <ToggleForm
                    {...register('useDefaultPrompt', {
                      onChange: () => setUseDefaultPromptVal(!useDefaultPromptVal),
                    })}
                    /*@ts-ignore*/
                    defaultValue={useDefaultPromptVal}
                    label={T.translate('case.useDefaultPrompt')}
                    errors={errors}
                  />
                </div>
                {!useDefaultPromptVal && (
                  <>
                    <TextForm
                      as="textarea"
                      style={{ marginBottom: '1rem' }}
                      {...register('systemPrompt')}
                      /*@ts-ignore*/
                      label={T.translate('case.systemPrompt')}
                      placeholder={T.translate('case.systemPromptPlaceholder')}
                      defaultValue={systemPrompt}
                      errors={errors}
                    />
                    <TextForm
                      as="textarea"
                      style={{ marginBottom: '1rem' }}
                      {...register('sourcePrompt')}
                      /*@ts-ignore*/
                      label={T.translate('case.sourcePrompt')}
                      placeholder={T.translate('case.sourcePromptPlaceholder')}
                      defaultValue={sourcePrompt}
                      errors={errors}
                    />
                  </>
                )}
                <div style={{ marginBottom: '1rem', width: '50%' }}>
                  <ToggleForm
                    {...register('useChunkUpStrategy', {
                      onChange: () => setUseChunkUpStrategyVal(!useChunkUpStrategyVal),
                    })}
                    /*@ts-ignore*/
                    defaultValue={useChunkUpStrategyVal}
                    label={T.translate('case.useChunkUpStrategy')}
                    errors={errors}
                  />
                </div>
                {useChunkUpStrategyVal && (
                  <div style={{ display: 'flex', flexDirection: 'row', marginBottom: '1rem' }}>
                    <div style={{ width: '50%' }}>
                      <TextForm
                        type="number"
                        style={{ marginBottom: '1rem' }}
                        {...register('chunkUpWhenLessThanMatches')}
                        /*@ts-ignore*/
                        label={T.translate('case.chunkUpWhenLessThanMatches')}
                        placeholder={T.translate('case.chunkUpWhenLessThanMatchesPlaceholder')}
                        defaultValue={chunkUpWhenLessThanMatches}
                        errors={errors}
                      />
                    </div>
                    <div style={{ width: '50%', marginLeft: '1rem' }}>
                      <TextForm
                        type="number"
                        style={{ marginBottom: '1rem' }}
                        {...register('chunkEitherSideOfMatch')}
                        /*@ts-ignore*/
                        label={T.translate('case.chunkEitherSideOfMatch')}
                        placeholder={T.translate('case.chunkEitherSideOfMatchPlaceholder')}
                        defaultValue={chunkEitherSideOfMatch}
                        errors={errors}
                      />
                    </div>
                  </div>
                )}
                <div style={{ marginBottom: '1rem', width: '50%' }}>
                  <ToggleForm
                    {...register('suggestedQuestions')}
                    /*@ts-ignore*/
                    defaultValue={suggestedQuestions}
                    label={T.translate('case.suggestedQuestions')}
                    errors={errors}
                  />
                </div>
                <div style={{ marginBottom: '1rem', width: '50%' }}>
                  <ToggleForm
                    {...register('queryRouter')}
                    /*@ts-ignore*/
                    defaultValue={queryRouter}
                    label={T.translate('case.queryRouter')}
                    errors={errors}
                  />
                </div>
              </>
            )}
          </Form.Group>
        </Form>
      </div>
    </Modal>
  );
};

export default SearchOptions;
