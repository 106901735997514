import React from 'react';
import SearchAnswerContainer from './SearchAnswerContainer';

import {
  Box,
  Typography,
  Paper,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from '@mui/material';
import { formatCamelCase } from 'utils/strings';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

// Recursive component to handle rendering dynamic objects
export const RecursiveRenderer = ({ data }: any) => {
  const removeKeys = ['id', 'startPage', 'endPage', 'status', 'created'];
  // Check if the current data is an object
  if (typeof data === 'object' && data !== null) {
    if (Array.isArray(data)) {
      // If data is an array, map through the array and render items
      return (
        <Box component="ul" sx={{ pl: 3 }}>
          {data.map((item, index) => (
            <Box component="li" key={index}>
              <RecursiveRenderer data={item} />
            </Box>
          ))}
        </Box>
      );
    } else {
      // If data is an object, map through its keys and render key-value pairs
      return (
        <Box sx={{ pl: 0 }}>
          {Object.entries(data).map(
            ([key, value], index) =>
              value &&
              !removeKeys.includes(key) && (
                <Box key={index} sx={{ mb: 1 }}>
                  {key === 'evidenceSummaries' ? (
                    data.status === 'queued' ? (
                      <div style={{ display: 'flex', marginLeft: '0.5rem' }}>
                        <Typography component="span" sx={{ fontWeight: 600 }}>
                          {formatCamelCase(key)}:&nbsp;
                        </Typography>
                        <Typography
                          component="span"
                          style={{
                            lineBreak: 'auto',
                            animation: `blinker 1s linear infinite`,
                            fontWeight: 600,
                            color: '#0000007a',
                          }}
                        >
                          Generating summary...
                        </Typography>
                      </div>
                    ) : data.status === 'failed' ? (
                      <>
                        <Typography component="span" sx={{ fontWeight: 600 }}>
                          {formatCamelCase(key)}:&nbsp;
                        </Typography>
                        <Typography component="span">
                          An issue occured while generating Summary
                        </Typography>
                      </>
                    ) : (
                      <>
                        <Typography component="span" sx={{ fontWeight: 600 }}>
                          {formatCamelCase(key)}:&nbsp;
                        </Typography>
                        <RecursiveRenderer data={value} />
                      </>
                    )
                  ) : (
                    <>
                      <Typography component="span" sx={{ fontWeight: 600 }}>
                        {formatCamelCase(key)}:&nbsp;
                      </Typography>
                      <RecursiveRenderer data={value} />
                    </>
                  )}
                </Box>
              ),
          )}
        </Box>
      );
    }
  } else {
    // If data is neither an object nor an array, just render the value
    return <Typography component="span">{String(data)}</Typography>;
  }
};

const DocSummary = ({ data, scrollBarRef, collapsable = false }: any) => {
  return typeof data.text === 'string' ? (
    <SearchAnswerContainer
      response={data.text}
      typingEffect={data.typingEffect}
      scrollBarRef={scrollBarRef}
      handleMatchesLinkClicked={() => null}
      allMatchesCount={data.matches.length}
    />
  ) : (
    <Box sx={{ p: 2, height: '100%', paddingBottom: collapsable ? 10 : 0 }}>
      <Paper sx={{ p: 1, height: '100%', backgroundColor: 'transparent' }}>
        {data.length > 0 ? (
          data.map((item: any, index: number) =>
            collapsable ? (
              <Accordion key={index}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls={`panel${index}-content`}
                  id={`panel${index}-header`}
                >
                  <Typography sx={{ fontWeight: 'bold' }}>
                    {item.barrister?.name || item.witness?.name || `Summary ${index + 1}`}
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <RecursiveRenderer data={item} />
                </AccordionDetails>
              </Accordion>
            ) : (
              <RecursiveRenderer key={index} data={item} />
            ),
          )
        ) : (
          <Typography
            sx={{
              padding: 10,
              textAlign: 'center',
            }}
          >
            No summaries available.
          </Typography>
        )}
      </Paper>
    </Box>
  );
};

export default DocSummary;
