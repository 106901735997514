import React from 'react';
import { Typography, Grid } from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';
import T from 'i18n';

const useStyles = makeStyles((theme: any) =>
  createStyles({
    screenShareBanner: {
      width: '100%',
      zIndex: 99999999,
      position: 'absolute',
      background: 'rgba(116, 141, 236, 0.5)',
      '& h6': {
        color: 'white',
        fontSize: '1rem',
      },
      pointerEvents: 'none',
      [theme.breakpoints.down('sm')]: {
        bottom: `${theme.trialViewFooterHeight + theme.mobileFooterHeight}px`,
      },
    },
  }),
);

export default () => {
  const classes = useStyles();

  return (
    <Grid
      container
      justifyContent="center"
      alignItems="center"
      className={classes.screenShareBanner}
    >
      <Typography variant="h6">{T.translate('common.notIdealBrowser')}</Typography>
    </Grid>
  );
};
