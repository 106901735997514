import initialState from 'features/common/redux/initialState';
import { CASE_TOGGLE_SIDEBAR } from 'features/case/redux/constants';

export function toggleSidebar() {
  return { type: CASE_TOGGLE_SIDEBAR };
}

export const SET_SIDEBAR_OPEN = 'SET_SIDEBAR_OPEN';

export function setSidebarOpen(isOpen) {
  return { type: SET_SIDEBAR_OPEN, payload: isOpen };
}

export function reducer(state = initialState, action) {
  switch (action.type) {
    case CASE_TOGGLE_SIDEBAR:
      return {
        ...state,
        isSidebarOpen: !state.isSidebarOpen,
      };
    case SET_SIDEBAR_OPEN:
      return {
        ...state,
        isSidebarOpen: action.payload,
      };
    default:
      return state;
  }
}
