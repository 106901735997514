import * as React from 'react';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import Grid from '@mui/material/Grid';
import parse from 'autosuggest-highlight/parse';
import match from 'autosuggest-highlight/match';
import { debounce } from '@mui/material/utils';
import { makeStyles } from '@mui/styles';
import { makeCancelable } from 'utils/promises';
import { Chip, InputAdornment, Tooltip } from '@mui/material';
import WarningIcon from '@mui/icons-material/Warning';
import SimpleTooltip from './SimpleTooltip';
import { useMediaQuery, useTheme } from '@mui/material';
import { ListChildComponentProps, FixedSizeList } from 'react-window';
import { CustomScrollbars } from '../Table/CustomScrollbarsVirtualList';
import { useDispatch } from 'react-redux';
import clsx from 'clsx';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { isObject } from 'utils/object';

export const CUSTOM_FLAG = 'CUSTOM';

function removeDuplicates(originalArray: any, prop: string) {
  var newArray = [];
  var lookupObject = {} as any;

  for (var i in originalArray) {
    lookupObject[originalArray[i][prop]] = originalArray[i];
  }

  for (i in lookupObject) {
    newArray.push(lookupObject[i]);
  }
  return newArray;
}

const useStyles = makeStyles(() => ({
  noBorder: {
    border: 'none',
  },
  noPadding: {
    padding: 0,
  },
  inline: {
    '& .MuiInputBase-root': {
      flexWrap: 'nowrap',
    },
    '& .MuiButtonBase-root': {
      height: '1.6rem',
    },
  },
  root: {
    '& .MuiOutlinedInput-root': {
      padding: '0 9px',
    },
    '& .Mui-readOnly': {
      backgroundColor: '#f5f5f5',
      cursor: 'not-allowed',
    },
  },
}));

export default function Lookup({
  loadOptions,
  minInputLength,
  valueKey,
  valueKey0 = undefined,
  labelKey,
  subLabelKey,
  onCustomSelect,
  autoFocus,
  onFocus,
  onChange,
  sx,
  readOnly,
  helperText,
  warning,
  error,
  customInput,
  fetchFunction,
  inline,
  startAdornment,
  ...rest
}: any) {
  const [inputValue, setInputValue] = React.useState('');
  const [options, setOptions] = React.useState<readonly any[]>([]);
  const [lastFetchedOptions, setLastFetchedOptions] = React.useState(
    rest.value ? [rest.value] : [],
  );

  const value = rest.value || (rest.multiple ? [] : null);

  let fetchOptionsSafe = React.useRef<any>(null);

  const createNewOption = React.useCallback(
    (inputValue: any) => ({
      [valueKey]: inputValue,
      type: CUSTOM_FLAG,
    }),
    [valueKey],
  );

  const dispatch = useDispatch();

  const fetch = React.useMemo(
    () =>
      debounce((request: { input: string }, callback: (results?: readonly any[]) => void) => {
        let newLastFetchedOptions = [] as any;
        fetchOptionsSafe.current && fetchOptionsSafe.current.cancel();
        const returnStuff = (stuff: any) => {
          callback(stuff);
          setLastFetchedOptions(stuff);
        };

        if (inputValue) {
          newLastFetchedOptions = customInput ? [createNewOption(inputValue)] : [];

          if (inputValue.length >= minInputLength) {
            fetchOptionsSafe.current = makeCancelable(
              new Promise(resolve =>
                (loadOptions ? loadOptions(inputValue) : dispatch(fetchFunction(inputValue))).then(
                  (res: any) => {
                    newLastFetchedOptions = newLastFetchedOptions.concat(res);
                    resolve(newLastFetchedOptions);
                  },
                ),
              ),
            );
            fetchOptionsSafe.current.promise.then(
              (res: any) => returnStuff(res),
              () => {},
            );
          } else {
            returnStuff(newLastFetchedOptions);
          }
        } else {
          returnStuff(newLastFetchedOptions);
        }
      }, 400),
    [
      inputValue,
      customInput,
      createNewOption,
      minInputLength,
      loadOptions,
      dispatch,
      fetchFunction,
    ],
  );

  React.useEffect(() => {
    let active = true;

    if (inputValue === '') {
      setOptions(rest.multiple ? lastFetchedOptions : value ? [value] : []);
      return undefined;
    }

    fetch({ input: inputValue }, (results?: readonly any[]) => {
      if (active) {
        let newOptions: readonly any[] = [];

        if (value) {
          newOptions = Array.isArray(value) ? value : [value];
        }

        if (results) {
          const newOptionsWithDuplicates = [...newOptions, ...results];
          newOptions = !valueKey
            ? [...new Set(newOptionsWithDuplicates)]
            : removeDuplicates(newOptionsWithDuplicates, valueKey);
        }

        setOptions(newOptions);
      }
    });

    return () => {
      active = false;
      fetch && fetch.clear();
      fetchOptionsSafe.current && fetchOptionsSafe.current.cancel();
    };
  }, [value, inputValue, fetch, rest.multiple /*, lastFetchedOptions (removed on purpose) */]);

  const classes = useStyles();

  const OverflowRowItem = ({ children, ...props }: any) => {
    const textRef = React.useRef<HTMLElement | null>(null);
    const [isOverflowed, setIsOverflowed] = React.useState(false);

    React.useEffect(() => {
      const element = textRef.current;
      if (element) {
        setIsOverflowed(element.scrollWidth > element.clientWidth);
      }
    }, [children]);

    return (
      <Tooltip
        title={isOverflowed ? children : ''}
        disableHoverListener={!isOverflowed}
        {...props}
        placement="top"
        arrow
      >
        <Box
          ref={textRef}
          sx={{
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            width: '100%',
            display: 'inline-block',
          }}
        >
          {children}
        </Box>
      </Tooltip>
    );
  };

  function renderRow(props: ListChildComponentProps) {
    const { data, index, style } = props;
    const dataSet = data[index];

    return (
      dataSet && (
        <li {...dataSet[0]} style={{ ...style }}>
          <Grid container alignItems="center">
            <Grid item sx={{ width: '100%', wordWrap: 'break-word' }}>
              <Box
                sx={{
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  whiteSpace: 'nowrap',
                  width: '100%',
                  display: 'inline-block',
                  lineHeight: 1,
                }}
              >
                {dataSet[1].map((part: any, index1: number) => (
                  <Box
                    key={index1}
                    component="span"
                    sx={{ fontWeight: part.highlight ? 'bold' : 'regular' }}
                  >
                    {part.text}
                  </Box>
                ))}
              </Box>
              {dataSet[2] && (
                <Box
                  sx={{
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    whiteSpace: 'nowrap',
                    width: '100%',
                    display: 'inline-block',
                    lineHeight: 0.8,
                  }}
                >
                  {dataSet[2].map((part: any, index2: number) => (
                    <Box
                      key={index2}
                      component="span"
                      sx={{
                        fontSize: '1rem',
                        fontWeight: part.highlight ? 'bold' : 'italic',
                        color: 'gray',
                      }}
                    >
                      {part.text}
                    </Box>
                  ))}
                </Box>
              )}
              {dataSet[3] && (
                <OverflowRowItem>
                  {dataSet[3].map((part: any) => (
                    <Box
                      key={part.text}
                      component="span"
                      sx={{
                        fontWeight: part.highlight ? 'bold' : 'italic',
                        color: 'gray',
                      }}
                    >
                      {part.text}
                    </Box>
                  ))}
                </OverflowRowItem>
              )}
            </Grid>
          </Grid>
        </li>
      )
    );
  }

  const OuterElementContext = React.createContext({});

  const OuterElementType = React.forwardRef<HTMLDivElement>((props, ref) => {
    const outerProps = React.useContext(OuterElementContext);

    return (
      <div ref={ref} {...props} {...outerProps}>
        <CustomScrollbars
          {...(props as any)}
          style={{ height: 'calc(100% - 1px)' }}
          forwardedRef={ref}
        />
      </div>
    );
  });

  // Adapter for react-window
  const ListboxComponent = React.forwardRef<HTMLDivElement, React.HTMLAttributes<HTMLElement>>(
    function ListboxComponent(props, ref) {
      const { children, ...other } = props;
      const itemData: React.ReactElement[] = [];

      if (children) {
        (children as React.ReactElement[]).forEach(
          (item: React.ReactElement & { children?: React.ReactElement[] }) => {
            itemData.push(item);
            itemData.push(...(item?.children || []));
          },
        );
      }

      const theme = useTheme();
      const smUp = useMediaQuery(theme.breakpoints.up('sm'), {
        noSsr: true,
      });
      const itemCount = itemData.length;
      const itemSize = smUp ? (!valueKey ? 30 : 60) : !valueKey ? 40 : 70;

      return (
        <div ref={ref}>
          <OuterElementContext.Provider value={other}>
            <FixedSizeList
              itemData={itemData}
              height={(itemSize + 5) * Math.min(itemCount, 8)}
              width="100%"
              outerElementType={OuterElementType}
              innerElementType="ul"
              itemSize={itemSize}
              overscanCount={5}
              itemCount={itemCount}
            >
              {renderRow}
            </FixedSizeList>
          </OuterElementContext.Provider>
        </div>
      );
    },
  );

  return (
    <Autocomplete
      {...rest}
      size="small"
      readOnly={readOnly}
      options={options}
      getOptionLabel={(option: any) => {
        return !isObject(option) ? option : (option as any)[valueKey];
      }}
      filterOptions={(x: any) => x}
      classes={{ root: clsx(classes.root, inline && classes.inline) }}
      renderInput={params => (
        <TextField
          {...params}
          variant={rest.variant}
          autoFocus={autoFocus}
          placeholder={rest.placeholder}
          helperText={helperText}
          error={error}
          InputProps={{
            readOnly,
            ...params.InputProps,
            classes: {
              notchedOutline: classes.noBorder,
              root: classes.noPadding,
            },
            ...((!value || (Array.isArray(value) && value.length === 0)) &&
              !warning &&
              startAdornment && {
                startAdornment: (
                  <>
                    <InputAdornment position="start">{startAdornment}</InputAdornment>
                    {params.InputProps.startAdornment}
                  </>
                ),
              }),
            ...(warning && {
              startAdornment: (
                <InputAdornment position="start">
                  <SimpleTooltip title={warning} startOpenned>
                    <div>
                      <WarningIcon />
                    </div>
                  </SimpleTooltip>
                </InputAdornment>
              ),
            }),
          }}
          onFocus={onFocus}
          fullWidth
        />
      )}
      value={value}
      noOptionsText={rest.noOptionsText}
      onChange={(event: any, newValue: any) => {
        const newOptionsWithDuplicates = rest.multiple
          ? (newValue || []).concat(options)
          : [newValue, ...options].filter(Boolean);
        const newOptions = !valueKey
          ? [...new Set(newOptionsWithDuplicates)]
          : removeDuplicates(newOptionsWithDuplicates, valueKey);

        setOptions(newOptions);
        // setValue(newValue);
        setInputValue('');
        setLastFetchedOptions(newOptions);
        onChange && onChange(newValue);
        customInput && onCustomSelect && onCustomSelect(newValue);
      }}
      onInputChange={(event: any, inputValue: any, reason: any) => {
        if (inputValue === undefined) return;

        setInputValue(inputValue);
        if (['reset', 'leave'].includes(reason)) return;

        // if (inputValue) {
        //   const newLastFetchedOptions = lastFetchedOptions;
        //   let index = newLastFetchedOptions.findIndex((props: any) => props.type === CUSTOM_FLAG);
        //   if (index > -1) newLastFetchedOptions[index][valueKey] = inputValue;
        //   else
        //     index = customInput ? newLastFetchedOptions.push(createNewOption(inputValue)) - 1 : 0;

        //   setLastFetchedOptions(newLastFetchedOptions);

        // onChange && onChange(newLastFetchedOptions[index]);
        // onCustomSelect && onCustomSelect(newLastFetchedOptions[index]);
        // } else {
        // onChange && onChange(rest.multiple ? undefined : null);
        // onCustomSelect && onCustomSelect(rest.multiple ? undefined : null);
        // }
      }}
      ListboxComponent={ListboxComponent}
      disableListWrap
      renderGroup={params => params as any}
      includeInputInList
      filterSelectedOptions
      freeSolo
      sx={sx}
      renderTags={(values, getTagProps, owner) => (
        <PerfectScrollbar
          options={inline && { suppressScrollY: true }}
          style={
            inline && {
              display: 'flex',
              height: '1.7rem',
              maxWidth: 'calc(100% - 2rem)',
              margin: '0.25rem',
              marginRight: '1rem',
            }
          }
        >
          {values &&
            values.map((value, index) => (
              <Chip
                label={owner.getOptionLabel(value)}
                size={owner.size}
                {...getTagProps({ index })}
                {...owner.ChipProps}
              />
            ))}
        </PerfectScrollbar>
      )}
      renderOption={(props, option: any) => {
        if (!labelKey) {
          const matches = option && match(option, inputValue || '', { insideWords: true });
          const parts = option && parse(option, matches);

          return [props, parts];
        }
        const matches = match((valueKey0 && option[valueKey0]) || option[valueKey], inputValue, {
          insideWords: true,
        });
        const parts = parse((valueKey0 && option[valueKey0]) || option[valueKey], matches);

        const parts1 =
          option[subLabelKey] &&
          parse(option[subLabelKey], match(option[subLabelKey], inputValue, { insideWords: true }));

        const matches2 =
          option[labelKey] && match(option[labelKey], inputValue, { insideWords: true });
        const parts2 = option[labelKey] && parse(option[labelKey], matches2);

        return [props, parts, parts1, parts2];
      }}
    />
  );
}
