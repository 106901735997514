import React from 'react';
import T from 'i18n';
// import PropTypes from 'prop-types';
import { isIOS, isSafari } from 'utils/browser';

import Logo from '../Logo';
import Footer from '../Footer';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChrome, faSafari } from '@fortawesome/free-brands-svg-icons';
import Button from 'react-bootstrap/Button';
import { useNonSupportedBrowser } from '../redux/hooks';
import { isBrowserBlacklisted } from 'utils/browser';
import NotIdealBrowserBanner from './NotIdealBrowserBanner';

const browserLink = (icon, title, link) => (
  <div className="browser-link">
    <a href={link} className="link" target="_blank" rel="noopener noreferrer">
      <FontAwesomeIcon icon={icon} alt={icon.iconName} />
    </a>
    <span className="nsb-text">{title}</span>
  </div>
);

export default function NotSupportedBrowser({ children }) {
  const { nonSupportedBrowser, setNonSupportedBrowser } = useNonSupportedBrowser();

  return isBrowserBlacklisted && !nonSupportedBrowser ? (
    <div className="common-not-supported-browser">
      <div className="content">
        <Logo showIcon={false} />
        <span className="title">{T.translate('common.notSupportedBrowser')}</span>
        <div className="text">{T.translate('common.notSupportedBrowserMessage')}</div>
        <div className="browser-links">
          {isIOS &&
            browserLink(faSafari, T.translate('common.safari'), T.translate('common.safariLink'))}
          {!isIOS &&
            browserLink(faChrome, T.translate('common.chrome'), T.translate('common.chromeLink'))}
        </div>
        <div className="text">{T.translate('common.notSupportedBrowserMessage2')}</div>

        <Button variant="link" className="link" onClick={setNonSupportedBrowser}>
          {T.translate('common.iWishToProceed')}
        </Button>
      </div>
      <Footer className="white" itemClassName="white b--white" />
    </div>
  ) : (
    <>
      {isSafari && <NotIdealBrowserBanner />}
      {children}
    </>
  );
}

NotSupportedBrowser.propTypes = {};
NotSupportedBrowser.defaultProps = {};
