import React from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
} from '@mui/material';
import T from 'i18n';
import { makeStyles } from '@mui/styles';

type MappingPreviewProps = {
  open: boolean;
  onClose: () => void;
  mappingData: any[];
  fileIdentifier: string | undefined;
};
const useStyles = makeStyles({
  tableContainer: {
    maxHeight: '400px',
    overflowY: 'auto',
    overflowX: 'auto',
  },
  table: {
    width: '100%',
    borderCollapse: 'collapse',
  },
  th: {
    borderBottom: '1px solid #ccc',
    textAlign: 'left',
    padding: '8px',
    position: 'sticky',
    top: 0,
    backgroundColor: '#f9f9f9',
    zIndex: 1,
  },
  td: {
    borderBottom: '1px solid #eee',
    padding: '8px',
  },
  warningIcon: {
    color: 'grey',
    marginRight: '8px',
    height: 12,
    width: 12,
  },
});

const MappingPreview: React.FC<MappingPreviewProps> = ({
  open,
  onClose,
  mappingData,
  fileIdentifier,
}) => {
  const classes = useStyles();

  let displayedColumns = mappingData.length > 0 ? Object.keys(mappingData[0]) : [];
  if (displayedColumns.includes(fileIdentifier as string)) {
    displayedColumns = [
      fileIdentifier as string,
      ...displayedColumns.filter(col => col !== fileIdentifier),
    ];
  }
  return (
    <Dialog open={open} onClose={onClose} maxWidth="lg" fullWidth>
      <DialogTitle>{T.translate('case.batchUpload.dataMapper.preview.previewTitle')}</DialogTitle>
      <DialogContent className={classes.tableContainer}>
        {mappingData.length === 0 ? (
          <Typography variant="body1" color="textSecondary">
            {T.translate('case.batchUpload.dataMapper.preview.noPreviewData')}
          </Typography>
        ) : (
          <table className={classes.table}>
            <thead>
              <tr>
                {displayedColumns.map(col => (
                  <th key={col} className={classes.th}>
                    {col === fileIdentifier ? <strong>File identifier*</strong> : col}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {mappingData.map((row, rowIndex) => (
                <tr key={rowIndex}>
                  {displayedColumns.map(col => (
                    <td key={col} className={classes.td}>
                      {row[col] !== undefined ? String(row[col]) : ''}
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>
          {T.translate('case.batchUpload.dataMapper.preview.closePreview')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
export default MappingPreview;
