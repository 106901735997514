import { Button, Grid, Typography } from '@mui/material';
import React, { useEffect } from 'react';
import T from 'i18n';
import SearchInput from './SearchInput';
import SearchInfo from './SearchInfo';
import SuggestedTopic from './SuggestedTopic';

interface NewSearchContainerProps {
  handleSearch: Function;
  filters: any;
  askAIHandler: Function;
  handleChangeFilters: Function;
}

export const NewSearchContainer = ({
  filters,
  askAIHandler,
  handleSearch,
  handleChangeFilters,
}: NewSearchContainerProps) => {
  const [summaryTopic, setSummaryTopic] = React.useState<null | String>(null);

  useEffect(() => {
    if (summaryTopic && summaryTopic === filters.phrase) {
      handleSearch();
      setSummaryTopic(null);
    }
    return () => {
      setSummaryTopic(null);
    };
  }, [summaryTopic, filters.phrase, handleSearch]);
  return (
    <>
      <Grid
        container
        height="100%"
        sx={{ alignContent: 'center', justifyContent: 'center', mt: '-4.5rem' }}
        spacing={1}
      >
        <Grid
          item
          xs={12}
          sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
          }}
        >
          <Typography variant="h3" sx={{ color: '#212529', fontWeight: 700 }}>
            {T.translate('case.enterTopicHEading')}
          </Typography>
          <Button
            style={{
              padding: 0,
              height: 'fit-content',
              minWidth: 0,
            }}
          >
            <SearchInfo isNewSearch={true} />
          </Button>
        </Grid>
        <Grid item xs={12}>
          <SearchInput
            filters={filters}
            isAnswersMode={false}
            isNewSearch={true}
            handleChangeFilters={handleChangeFilters}
            handleSearch={handleSearch}
          />
        </Grid>
        {filters.files.length > 0 && (
          <Grid item xs={12}>
            <SuggestedTopic filters={filters} askAIHandler={askAIHandler} isNewSearch={true} />
          </Grid>
        )}
      </Grid>
    </>
  );
};
