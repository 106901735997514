import React from 'react';
import { Compare, PhotoFilter } from '@mui/icons-material';
import { Chip } from '@mui/material';
import { useSetAISearchState } from '../redux/setAISearchState';

const SuggestedTopic = ({ filters, askAIHandler, isNewSearch }: any) => {
  const { searchAnsChat } = useSetAISearchState();
  return (
    <>
      {filters && filters.files && filters.files.length > 0 && (
        <div style={{ textAlign: 'center' }}>
          {isNewSearch && filters.files.length === 1 && (
            <Chip
              icon={<PhotoFilter color="primary" fontSize={isNewSearch ? 'medium' : 'small'} />}
              label={`Summarize ${filters.files[0].id}`}
              variant="outlined"
              size={isNewSearch ? 'medium' : 'small'}
              sx={{ fontSize: `${isNewSearch ? '1.1rem' : '0.89rem'}`, mt: 1 }}
              onClick={() => askAIHandler('summarize', filters.files[0].id, 'transcript')}
            />
          )}
          {filters.files.length === 2 && (
            <>
              {filters.files.map(
                (file: any, index: number) =>
                  (searchAnsChat.length === 0 ||
                    !searchAnsChat.find((item: any) => item.text === `summarize ${file.id}`)) && (
                    <Chip
                      key={file.id}
                      icon={
                        <PhotoFilter color="primary" fontSize={isNewSearch ? 'medium' : 'small'} />
                      }
                      label={`Summarize ${file.id}`}
                      variant="outlined"
                      size={isNewSearch ? 'medium' : 'small'}
                      sx={{
                        fontSize: `${isNewSearch ? '1.1rem' : '0.89rem'}`,
                        mr: index === 0 ? 1 : 0,
                        ml: index === 1 ? 1 : 0,
                        mt: 1,
                      }}
                      onClick={() => askAIHandler('summarize', file.id, 'transcript')}
                    />
                  ),
              )}
              {!searchAnsChat.find(
                (item: any) =>
                  item.text === `compare ${filters.files[0].id} & ${filters.files[1].id}`,
              ) && (
                <Chip
                  icon={<Compare color="primary" fontSize={isNewSearch ? 'medium' : 'small'} />}
                  variant="outlined"
                  size={isNewSearch ? 'medium' : 'small'}
                  label={`Compare ${filters.files[0].id} & ${filters.files[1].id}`}
                  sx={{ fontSize: `${isNewSearch ? '1.1rem' : '0.89rem'}`, ml: 1, mt: 1 }}
                  onClick={() =>
                    askAIHandler(
                      'compare',
                      `${filters.files[0].id} & ${filters.files[1].id}`,
                      'transcript',
                    )
                  }
                  disabled={
                    !(
                      searchAnsChat.find(
                        (item: any) => item.text === `summarize ${filters.files[0].id}`,
                      ) &&
                      searchAnsChat.find(
                        (item: any) => item.text === `summarize ${filters.files[1].id}`,
                      )
                    )
                  }
                />
              )}
            </>
          )}
        </div>
      )}
    </>
  );
};

export default SuggestedTopic;
