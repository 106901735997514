import React, { useState } from 'react';
import T from 'i18n';
import { Spinner } from 'features/common';
import { useDispatch } from 'react-redux';
import { fetchFolderHealth } from './redux/fetchFolderHealth';
import {
  Autocomplete,
  Button,
  CircularProgress,
  Collapse,
  Divider,
  Grid,
  IconButton,
  ListSubheader,
  Popper,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { CheckCircle, Error, ExpandLess, ExpandMore } from '@mui/icons-material';
import HealthCheckTable from './HealthCheckTable';
import { flattenFolders, removeIndexFolders } from 'utils/foldersStructuring';
import { useSelector } from 'react-redux';
import { selectFolders } from 'common/selectors';
import { assignDocuments } from './redux/assignDocuments';
import { autocompleteClasses } from '@mui/material/Autocomplete';
import { styled } from '@mui/styles';
import { VariableSizeList, ListChildComponentProps } from 'react-window';
import { CustomScrollbars } from 'features/common/Table/CustomScrollbarsVirtualList';
import { reIndexBundle } from './redux/reIndexBundle';

interface HealthCheckModalProps {
  folderId: string;
}

function renderRow(props: ListChildComponentProps) {
  const { data, index, style } = props;
  const dataSet = data[index];
  const inlineStyle = {
    ...style,
    top: (style.top as number) + 8,
  };

  if (dataSet[3].hasOwnProperty('group') && dataSet[3].group) {
    return (
      <ListSubheader
        key={dataSet.key}
        component="div"
        style={{ ...inlineStyle, marginLeft: `${dataSet[3].level || 0}rem` }}
      >
        {dataSet[1]}
      </ListSubheader>
    );
  }

  return (
    <Typography
      key={index}
      component="li"
      {...dataSet[0]}
      noWrap
      style={{ ...inlineStyle, marginLeft: `${dataSet[3].level || 0}rem` }}
    >
      {dataSet[1]}
    </Typography>
  );
}

function useResetCache(data: any) {
  const ref = React.useRef<VariableSizeList>(null);
  React.useEffect(() => {
    if (ref.current != null) {
      ref.current.resetAfterIndex(0, true);
    }
  }, [data]);
  return ref;
}

const StyledPopper = styled(Popper)({
  [`& .${autocompleteClasses.listbox}`]: {
    boxSizing: 'border-box',
    '& ul': {
      padding: 0,
      margin: 0,
    },
  },
});

const OuterElementContext = React.createContext({});

const OuterElementType = React.forwardRef<HTMLDivElement>((props, ref) => {
  const outerProps = React.useContext(OuterElementContext);

  return (
    <div ref={ref} {...props} {...outerProps}>
      <CustomScrollbars
        {...(props as any)}
        style={{ height: 'calc(100% - 1px)' }}
        forwardedRef={ref}
      />
    </div>
  );
});

const ListboxComponent = React.forwardRef<HTMLDivElement, React.HTMLAttributes<HTMLElement>>(
  function ListboxComponent(props, ref) {
    const { children, ...other } = props;
    const itemData: React.ReactElement[] = [];

    (children as React.ReactElement[]).forEach(
      (item: React.ReactElement & { children?: React.ReactElement[] }) => {
        itemData.push(item);
        itemData.push(...(item.children || []));
      },
    );

    const theme = useTheme();
    const smUp = useMediaQuery(theme.breakpoints.up('sm'), {
      noSsr: true,
    });
    const itemCount = itemData.length;
    const itemSize = smUp ? 36 : 48;

    const getChildSize = (child: React.ReactElement) => {
      if (child.hasOwnProperty('group')) {
        return 48;
      }

      return itemSize;
    };

    const getHeight = () => {
      if (itemCount > 8) {
        return 8 * itemSize;
      }
      return itemData.map(getChildSize).reduce((a, b) => a + b, 0);
    };

    const gridRef = useResetCache(itemCount);

    return (
      <div ref={ref}>
        <OuterElementContext.Provider value={other}>
          <VariableSizeList
            itemData={itemData}
            height={getHeight() + 2 * 8}
            width="100%"
            ref={gridRef}
            outerElementType={OuterElementType}
            innerElementType="ul"
            itemSize={index => getChildSize(itemData[index])}
            overscanCount={5}
            itemCount={itemCount}
          >
            {renderRow}
          </VariableSizeList>
        </OuterElementContext.Provider>
      </div>
    );
  },
);

const CopyToFolder = ({ docs }: any) => {
  const dispatch = useDispatch();
  const folders = useSelector(selectFolders);
  const trialBundlesWithoutIndexFolder = removeIndexFolders(folders['trialbooks']);
  const trialBundlesMenu = flattenFolders(trialBundlesWithoutIndexFolder);
  const teamBundlesMenu = flattenFolders(folders['teamBundles']);
  const privateBundlesMenu = flattenFolders(folders['privateBundles']);
  const foldersList = [
    trialBundlesMenu && trialBundlesMenu.length > 0 && { id: 'trialBundle', name: 'Trial Bundles' },
    ...trialBundlesMenu,
    teamBundlesMenu && teamBundlesMenu.length > 0 && { id: 'teamBundles', name: 'Team Bundles' },
    ...teamBundlesMenu,
    privateBundlesMenu &&
      privateBundlesMenu.length > 0 && { id: 'privateBundles', name: 'Private Bundles' },
    ...privateBundlesMenu,
  ].filter(Boolean);
  const [selectedFolder, setSelectedFolder] = useState('');
  const [loadingCopyAction, setLoadingCopyAction] = useState(false);
  const [folderFilterVal, setFolderFilterVal] = useState('');
  const labelKey = 'name';
  const valKey = 'id';

  const copy = () => {
    if (selectedFolder) {
      setLoadingCopyAction(true);
      dispatch(
        assignDocuments({
          documentIds: docs.map(({ compositeKey }: { compositeKey: string }) => compositeKey),
          folderId: selectedFolder,
        }),
      ).finally(() => setLoadingCopyAction(false));
    }
  };

  return (
    <>
      {foldersList.length > 0 && (
        <div style={{ display: 'flex', flexDirection: 'row', flexGrow: 0.5 }}>
          <span>{T.translate('case.folderToMoveTo')}</span>
          <Autocomplete
            sx={{ flexGrow: 1, maxWidth: '400px', ml: '0.5rem' }}
            inputValue={folderFilterVal}
            onInputChange={(event, value, reason) => {
              if (reason !== 'reset') {
                setFolderFilterVal(value);
              }
            }}
            size="small"
            options={foldersList || []}
            getOptionLabel={(option: any) => (option[labelKey] ? option[labelKey] : '')}
            getOptionDisabled={option =>
              option[valKey] === 'trialBundle' ||
              option[valKey] === 'teamBundles' ||
              option[valKey] === 'privateBundles' ||
              (option.folders && option.folders.length > 0)
            }
            renderInput={params => <TextField {...params} variant="standard" />}
            value={foldersList.find((val: any) => val[valKey] === selectedFolder) || null}
            onChange={(e, value: any) => {
              if (value && value[valKey]) {
                setSelectedFolder(value[valKey]);
                setFolderFilterVal(value[labelKey] || '');
              } else {
                setSelectedFolder('');
                setFolderFilterVal('');
              }
            }}
            ListboxComponent={ListboxComponent}
            disableListWrap
            PopperComponent={StyledPopper}
            renderOption={(props, option, state) =>
              [props, labelKey ? option[labelKey] : option, state.index, option] as React.ReactNode
            }
            renderGroup={params => params as any}
          />
          <Button
            variant="outlined"
            size="small"
            color="primary"
            disabled={!selectedFolder || loadingCopyAction}
            sx={{ ml: '0.5rem' }}
            onClick={copy}
          >
            {loadingCopyAction ? <CircularProgress size={17} /> : T.translate('generic.copy')}
          </Button>
        </div>
      )}
    </>
  );
};

type ReIndexKeys =
  | 'filesWithBadPageCount'
  | 'filesWithMismatchedReplacements'
  | 'filesWithNoFileVersions'
  | 'filesWithNoPDF'
  | 'filesWithOverlappingPages'
  | 'filesNotInPinecone'
  | 'filesWithEmbeddingsFailures'
  | 'filesWithFailedEntities'
  | 'filesWithMissingChunks'
  | 'filesWithNoEntities'
  | 'filesWithNoJsonStorage'
  | 'filesWithNoOCR'
  | 'filesWithNoSearch'
  | 'filesWithNoSearchIndexXML';

const createReIndexData = (key: ReIndexKeys) => {
  const mapping = {
    filesWithBadPageCount: null,
    filesWithMismatchedReplacements: null,
    filesWithNoFileVersions: null,
    filesWithNoPDF: { clearCurrentJob: true, reindexGenerateJson: true },
    filesWithOverlappingPages: null,
    filesNotInPinecone: { reindexUpdatePinecone: true },
    filesWithEmbeddingsFailures: { failedEmbeddings: true },
    filesWithFailedEntities: null,
    filesWithMissingChunks: null,
    filesWithNoEntities: { reindexExtractEntities: true },
    filesWithNoJsonStorage: { reindexGenerateJson: true },
    filesWithNoOCR: { clearCurrentJob: true, reindexGenerateJson: true },
    filesWithNoSearch: { reindexUpdateSearchIndex: true },
    filesWithNoSearchIndexXML: { reindexUpdateSearchIndex: true },
    filesNotInAzureSearchIndex: { reindexUpdateSearchIndex: true },
  };
  return mapping[key];
};

const HealthCheckDataContainer = ({ data, openList, setOpenList, folderId }: any) => {
  const dispatch = useDispatch();
  const [isReIndexing, setIsReIndexing] = useState(false);
  const createResultHeading = (key: string) => {
    const words = key.split(/(?=[A-Z][a-z])/g);
    return words.map((word: string) => word.charAt(0).toUpperCase() + word.slice(1)).join(' ');
  };

  const reIndex = (value: any, key: string) => {
    setIsReIndexing(true);
    dispatch(
      reIndexBundle({
        folderId,
        data: {
          documentIds: value.map(({ id }: { id: string }) => id),
          clearCurrentJob: false,
          failedEmbeddings: false,
          reindexExtractDates: false,
          reindexExtractEntities: false,
          reindexGenerateJson: false,
          reindexUpdatePinecone: false,
          reindexUpdateSearchIndex: false,
          ...(createReIndexData(key as ReIndexKeys) ?? {}),
        },
      }),
    ).finally(() => setIsReIndexing(false));
  };

  return (
    <>
      {!data || (typeof data === 'object' && Object.keys(data).length === 0)
        ? T.translate('case.noDataFound')
        : Object.entries(data)?.map(([key, value]: any, index: number) => (
            <>
              <Grid
                container
                key={key}
                justifyContent={'space-between'}
                sx={{ mb: 0.5 }}
                alignItems={'center'}
              >
                <Grid item xs={8} sx={{ my: 1 }}>
                  <Typography variant="body1">
                    {createResultHeading(key)}
                    {value.length > 0 ? ` (${value.length})` : ''}
                  </Typography>
                </Grid>
                <Grid item xs={4} textAlign={'end'}>
                  {value.length === 0 ? (
                    <CheckCircle sx={{ color: 'green' }} />
                  ) : (
                    <>
                      <IconButton
                        onClick={() => setOpenList({ ...openList, [key]: !openList[key] })}
                      >
                        {openList[key] ? <ExpandLess /> : <ExpandMore />}
                      </IconButton>
                      <Error sx={{ color: 'red' }} />
                    </>
                  )}
                </Grid>
              </Grid>
              {value.length > 0 && (
                <Collapse in={openList[key]} timeout="auto" unmountOnExit>
                  <Grid container>
                    <Grid item xs={12} sx={{ display: 'flex', flexDirection: 'row' }}>
                      <CopyToFolder docs={value} />
                      {!!createReIndexData(key as ReIndexKeys) && (
                        <Button
                          variant="outlined"
                          size="small"
                          color="primary"
                          sx={{ ml: '0.5rem' }}
                          disabled={isReIndexing}
                          onClick={() => reIndex(value, key)}
                        >
                          {T.translate('generic.reIndex')}
                        </Button>
                      )}
                    </Grid>
                    <Grid item xs={12}>
                      <HealthCheckTable
                        healthCheckResult={value}
                        tableHeight={`${(value.length * 37 > 300 ? 300 : value.length * 37) +
                          37}px`}
                        outsideTableMargin="1rem"
                      />
                    </Grid>
                  </Grid>
                </Collapse>
              )}
              {index !== Object.entries(data).length - 1 && <Divider />}
            </>
          ))}
    </>
  );
};

const HealthCheckModal = ({ folderId }: HealthCheckModalProps) => {
  const dispatch = useDispatch();
  const [loadingFolderOpHealth, setLoadingFolderOpHealth] = useState(false);
  const [loadingSearchAIHealth, setLoadingSearchAIHealth] = useState(false);
  const [openList, setOpenList] = useState<any>({});
  const [folderOpHealthList, setFolderOpHealthList] = useState<any | null>(null);
  const [showFolderOpHealthList, setShowFolderOpHealthList] = useState<boolean>(false);
  const [showSearchAIHealthList, setShowSearchAIHealthList] = useState<boolean>(false);
  const [searchAIHealthList, setSearchAIHealthList] = useState<any | null>(null);

  const handleFolderOpHealthList = () => {
    if (!showFolderOpHealthList && !folderOpHealthList) {
      setShowFolderOpHealthList(true);
      setLoadingFolderOpHealth(true);
      dispatch(fetchFolderHealth({ folderId, healthCheckType: 'folderOperationsHealth' }))
        .then((res: any) => {
          setFolderOpHealthList(res);
          setOpenList({
            ...openList,
            ...Object.keys(res).reduce((acc: any, curr: any) => ({ ...acc, [curr]: false }), {}),
          });
        })
        .finally(() => setLoadingFolderOpHealth(false));
    } else setShowFolderOpHealthList(!showFolderOpHealthList);
  };

  const handleSearchAIHealthList = () => {
    if (!showSearchAIHealthList && !searchAIHealthList) {
      setShowSearchAIHealthList(true);
      setLoadingSearchAIHealth(true);
      dispatch(fetchFolderHealth({ folderId, healthCheckType: 'searchAiHealth' }))
        .then((res: any) => {
          setSearchAIHealthList(res);
          setOpenList({
            ...openList,
            ...Object.keys(res).reduce((acc: any, curr: any) => ({ ...acc, [curr]: false }), {}),
          });
        })
        .finally(() => setLoadingSearchAIHealth(false));
    } else setShowSearchAIHealthList(!showSearchAIHealthList);
  };

  return (
    <div style={{ margin: '1rem' }}>
      <div>
        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
          <Typography variant="h6">{T.translate('case.mergeOrAgreeFolder')}</Typography>
          <IconButton onClick={() => handleFolderOpHealthList()}>
            {showFolderOpHealthList ? <ExpandLess /> : <ExpandMore />}
          </IconButton>
        </div>
        <Collapse in={showFolderOpHealthList} timeout="auto" unmountOnExit sx={{ mx: '1rem' }}>
          {loadingFolderOpHealth ? (
            <Spinner />
          ) : (
            <HealthCheckDataContainer
              data={folderOpHealthList}
              openList={openList}
              setOpenList={setOpenList}
              folderId={folderId}
            />
          )}
        </Collapse>
      </div>
      <div>
        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
          <Typography variant="h6">{T.translate('case.searchAI')}</Typography>
          <IconButton onClick={() => handleSearchAIHealthList()}>
            {showSearchAIHealthList ? <ExpandLess /> : <ExpandMore />}
          </IconButton>
        </div>
        <Collapse in={showSearchAIHealthList} timeout="auto" unmountOnExit sx={{ mx: '1rem' }}>
          {loadingSearchAIHealth ? (
            <Spinner />
          ) : (
            <HealthCheckDataContainer
              data={searchAIHealthList}
              openList={openList}
              setOpenList={setOpenList}
              folderId={folderId}
            />
          )}
        </Collapse>
      </div>
    </div>
  );
};

export default HealthCheckModal;
