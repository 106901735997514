import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as actions from 'features/case/redux/actions';
import Sidebar from './SidebarCase';
import NavBarLinks from 'features/common/NavBarLinks';
import NavBar from 'features/common/NavBar';
import withAuth from 'features/auth/withAuth';
import Footer from 'features/common/Footer';
import CustomDragLayer from './CustomDragLayer';
import {
  selectCurrentCase,
  selectIsTeamAdmin,
  selectUserId,
  selectIsCaseAdmin,
  selectMatchedUrl,
  selectDeepSearch,
  selectAISearch,
} from 'common/selectors';
import { selectPermissions, selectIsAdmin, selectCaseDetails } from 'common/selectors';
import PageNotFound from 'features/common/PageNotFound';
import classnames from 'classnames';
import Fab from '@mui/material/Fab';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import Divider from '@mui/material/Divider';
import equal from 'react-fast-compare';
import CaseSettingsButton from './CaseSettingsButton';
import SearchProvider from 'features/common/Search/SearchProvider';
import { toggleSidebar } from '../common/redux/navbarLayout';
import UploadDrawerWrapper from './UploadDrawerWrapper';
import { setUploadDrawerOpen } from 'features/globalUpload/redux/uploadDrawer';

export class Layout extends PureComponent {
  static propTypes = {
    fetchCasesPending: PropTypes.bool.isRequired,
    fetchCaseDetailsPending: PropTypes.bool.isRequired,
    isSidebarOpen: PropTypes.bool.isRequired,
  };

  state = {
    searchDetails: null,
    searchFunctions: null,
    width: window.innerWidth,
  };

  searchHandler = searchDetails => {
    if (!equal(searchDetails, this.state.searchDetails)) this.setState({ searchDetails });
  };

  searchFunctionsHandler = searchFunctions => {
    this.setState({ searchFunctions });
  };

  showHideClicked = () => {
    this.props.toggleSidebar();
  };

  updateWindowWidth = width => {
    this.setState({ width });
  };

  searchRef = null;

  render() {
    if (this.props.fetchCasesPending || this.props.fetchCaseDetailsPending) return null;

    if (!(this.props.canBrowseDocuments || this.props.isSystemAdmin)) return <PageNotFound />;

    return (
      <div className="case-layout">
        <CustomDragLayer />
        {this.props.isSidebarOpen && this.state.width > 991 && (
          <Fab disableRipple={true} className="collapse-icon" onClick={this.showHideClicked}>
            <ChevronLeftIcon style={{ color: 'white' }} />
          </Fab>
        )}
        <Sidebar
          NavBarLinks={
            <NavBarLinks
              {...((this.props.isSystemAdmin ||
                this.props.isTeamAdmin ||
                this.props.isCaseAdmin) && {
                additionalButton: (
                  <CaseSettingsButton
                    isTeamAdmin={this.props.isTeamAdmin}
                    isSystemAdmin={this.props.isSystemAdmin}
                    isCaseAdmin={this.props.isCaseAdmin}
                  />
                ),
              })}
            />
          }
          isSidebarOpen={this.props.isSidebarOpen}
          updateWindowWidth={this.updateWindowWidth}
        />
        <UploadDrawerWrapper />
        <div
          id="main-panel"
          className={classnames('main-panel', { fullSizePanel: !this.props.isSidebarOpen })}
          ref="mainPanel"
        >
          <div className="nav-with-collapse">
            {this.state.width > 991 && !this.props.isSidebarOpen && (
              <div>
                <Fab disableRipple={true} className="expand-icon" onClick={this.showHideClicked}>
                  <ChevronRightIcon style={{ color: '#4567E5' }} />
                </Fab>
                <Divider style={{ marginTop: '1.5rem' }} />
              </div>
            )}
            <NavBar
              ref={el => (this.searchRef = el && el.searchRef)}
              hasSearch={!this.props.isAiSearchPage}
              clickToSearch={this.props.isClickToSearch}
              search={this.state.searchDetails}
              searchFunctions={this.state.searchFunctions}
              brandText={
                this.props.case.name +
                `${this.props.case.claimNumber ? ` ${this.props.case.claimNumber}` : ''}`
              }
              {...((this.props.isSystemAdmin ||
                this.props.isTeamAdmin ||
                this.props.isCaseAdmin) && {
                additionalButton: (
                  <CaseSettingsButton
                    isTeamAdmin={this.props.isTeamAdmin}
                    isSystemAdmin={this.props.isSystemAdmin}
                    isCaseAdmin={this.props.isCaseAdmin}
                  />
                ),
              })}
              isDataRoom={true}
            />
          </div>
          <SearchProvider
            searchHandler={this.searchHandler}
            searchFunctionsHandler={this.searchFunctionsHandler}
            clearSearch={this.searchRef}
          >
            <div className="content">{this.props.children}</div>
          </SearchProvider>
          <Footer />
        </div>
      </div>
    );
  }
}

/* istanbul ignore next */
function mapStateToProps(state) {
  const {
    folders: { canBrowseDocuments },
  } = selectPermissions(state);
  const isSystemAdmin = selectIsAdmin(state);
  const userId = selectUserId(state);
  const isTeamAdmin = selectIsTeamAdmin(state);
  const isCaseAdmin = selectIsCaseAdmin(state);
  const currentUrl = selectMatchedUrl(state);
  const isAiSearch = selectAISearch(state);
  const isDeepSearch = selectDeepSearch(state);
  const {
    home: { fetchCasesPending },
    case: { fetchCaseDetailsPending },
  } = state;
  const isSidebarOpen = state.common.isSidebarOpen;

  const caseDetails = selectCaseDetails(state);
  return {
    case: selectCurrentCase(state),
    fetchCasesPending,
    //if we loaded case details once no need to check for pending
    fetchCaseDetailsPending: !!caseDetails ? false : fetchCaseDetailsPending,
    isSystemAdmin,
    canBrowseDocuments,
    userId,
    isTeamAdmin,
    isCaseAdmin,
    isClickToSearch:
      currentUrl.includes(`${selectCurrentCase(state).id}/search-mode`) && isDeepSearch,
    isAiSearchPage: currentUrl.includes(`${selectCurrentCase(state).id}/ai-tools`) && isAiSearch,
    isSidebarOpen,
  };
}

/* istanbul ignore next */
const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators({ ...actions }, dispatch),
  toggleSidebar: () => dispatch(toggleSidebar()),
  setUploadDrawerOpen: (open, folderId) => dispatch(setUploadDrawerOpen(open, folderId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withAuth(Layout, { forceLogin: true }));
