// This is the root reducer of the feature. It is used for:
//   1. Load reducers from each action in the feature and process them one by one.
//      Note that this part of code is mainly maintained by Rekit, you usually don't need to edit them.
//   2. Write cross-topic reducers. If a reducer is not bound to some specific action.
//      Then it could be written here.
// Learn more from the introduction of this approach:
// https://medium.com/@nate_wang/a-new-approach-for-managing-redux-actions-91c26ce8b5da.

import initialState from './initialState';
import { reducer as fetchNotificationsReducer } from './fetchNotifications';
import { reducer as fetchUsersReducer } from './fetchUsers';
import { reducer as fetchRolesReducer } from './fetchRoles';
import { reducer as nonSupportedBrowserReducer } from './nonSupportedBrowser';
import { reducer as fetchUserSettingsReducer } from './fetchUserSettings';
import { reducer as updateUserSettingsReducer } from './updateUserSettings';
import { reducer as fetchUserDetailsReducer } from './fetchUserDetails';
import { reducer as fetchSystemStatusReducer } from './fetchSystemStatus';
import { reducer as fetchAllPermissionsReducer } from './fetchAllPermissions';
import { reducer as fetchRolesByTypeReducer } from './fetchRolesByType';
import { reducer as fetchHealthCheckReducer } from './fetchHealthCheck';
import { reducer as bookmarksReducer } from './bookmarks';
import { reducer as navbarLayoutReducer } from './navbarLayout';
import { reducer as uploadDrawerReducer } from '../../globalUpload/redux/uploadDrawer';

const reducers = [
  fetchNotificationsReducer,
  fetchUsersReducer,
  fetchRolesReducer,
  nonSupportedBrowserReducer,
  fetchUserSettingsReducer,
  updateUserSettingsReducer,
  fetchUserDetailsReducer,
  fetchSystemStatusReducer,
  fetchAllPermissionsReducer,
  fetchRolesByTypeReducer,
  fetchHealthCheckReducer,
  bookmarksReducer,
  navbarLayoutReducer,
  uploadDrawerReducer,
];

export default function reducer(state = initialState, action) {
  let newState;
  switch (action.type) {
    // Handle cross-topic actions here
    case 'HOME_FETCH_CASES_SUCCESS': {
      const { settings } = action.data;
      newState = {
        ...state,
        userSettings: settings,
      };
      break;
    }
    default:
      newState = state;
      break;
  }
  /* istanbul ignore next */
  return reducers.reduce((s, r) => r(s, action), newState);
}
