import React, { useState, useEffect, forwardRef } from 'react';
import AcusoftViewerControl from './AcusoftViewerControl';
import Spinner from 'features/common/Spinner';
import Error from './AcusoftViewerControl/ViewerError';
import Logger from 'utils/logger';
import {
  selectAccessToken,
  selectCurrentCaseId,
  selectMatchedUrl,
  selectMaxPagePrefetch,
  selectPresentQuery,
} from 'common/selectors';
import { useSelector } from 'react-redux';
import { useFetchViewingSessionId } from '../redux/fetchViewingSessionId';
import { Ajax } from '@prizmdoc/viewer-core';

interface Props {
  fileId: string;
  isPreviewMode?: boolean;
  hearingRoomMode?: 'private' | 'public';
  fileData?: {
    viewingSessionId?: string;
    prizmApiUrl?: string;
    viewingFilePrivate?: boolean;
    documentRotation?: number;
    passViewingSessionIdToReceivers?: boolean;
  };
  bundleSpecificFileDetails?: object;
  removeInput?: boolean;
  enableScroll?: boolean;
  searchHandler?: (searchDetails: any) => void;
  searchFunctionsHandler?: (searchFunctions: any) => void;
  isFullScreen?: boolean;
  sideSummaryOpen?: boolean;
  setSideSummaryOpen?: React.Dispatch<React.SetStateAction<boolean>>;
  fileHasSummary?: boolean;
}

export default forwardRef(
  (
    {
      fileId,
      isPreviewMode,
      hearingRoomMode,
      fileData,
      bundleSpecificFileDetails,
      removeInput,
      enableScroll,
      searchHandler,
      searchFunctionsHandler,
      isFullScreen,
      sideSummaryOpen,
      setSideSummaryOpen,
      fileHasSummary,
    }: Props,
    ref,
  ) => {
    const [viewerOptions, setViewerOptions] = useState(fileData);
    const [viewingSessionIdError, setViewingSessionIdError] = useState(false);

    const present = useSelector(selectPresentQuery) as 'private' | 'public' | undefined;
    const maxPagePrefetch = useSelector(selectMaxPagePrefetch);
    const currentUrl = useSelector(selectMatchedUrl);
    const caseId = useSelector(selectCurrentCaseId);
    const isPresentModePresent = present && currentUrl.includes(`${caseId}/present-mode`);
    const accessToken = useSelector(selectAccessToken);

    const { fetchViewingSessionId } = useFetchViewingSessionId();

    useEffect(() => {
      if (
        !viewerOptions?.viewingSessionId ||
        !viewerOptions?.prizmApiUrl ||
        viewerOptions?.viewingFilePrivate === undefined
      ) {
        fetchViewingSessionId(fileId)
          .then((res: any) => {
            setViewerOptions({
              viewingSessionId: res.viewingSessionId,
              prizmApiUrl: res.prizmApiUrl,
              viewingFilePrivate: res.file?.private,
              passViewingSessionIdToReceivers: res.passViewingSessionIdToReceivers,
            });
          })
          .catch((res: any) => {
            Logger.ERROR('Fetching viewing session ID', res.error);
            setViewingSessionIdError(true);
          });
      }
      // MOUNT
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
      Ajax.setHeaders({
        Authorization: `Bearer ${accessToken}`,
      });
    }, [accessToken]);

    // console.log('AcusoftViewer', 'render');

    return (
      <div className="viewing-acusoft-viewer">
        {viewingSessionIdError ? (
          <Error />
        ) : viewerOptions?.viewingSessionId && viewerOptions?.prizmApiUrl && accessToken ? (
          <AcusoftViewerControl
            key={viewerOptions.viewingSessionId}
            ref={ref}
            className="pcc-page-list"
            options={{
              documentID: viewerOptions.viewingSessionId,
              imageHandlerUrl: viewerOptions.prizmApiUrl,
              // maxPrefetch not a part of documentation, but was instructed by acusoft team in an email 08.07.22
              maxPrefetch: isPresentModePresent ? 0 : parseInt(maxPagePrefetch),
              pageRotation: viewerOptions.documentRotation || 0,
              // viewerAssetsPath: process.env.REACT_APP_VIEWER_ASSETS,
            }}
            fileId={fileId}
            viewingFilePrivate={viewerOptions.viewingFilePrivate}
            passViewingSessionIdToReceivers={viewerOptions.passViewingSessionIdToReceivers}
            firstParentTriaBundleFileDetails={bundleSpecificFileDetails}
            present={present}
            removeInput={removeInput}
            fileData={fileData}
            enableScroll={enableScroll}
            isFullScreen={isFullScreen}
            isPreviewMode={isPreviewMode}
            hearingRoomMode={hearingRoomMode}
            searchHandler={searchHandler}
            searchFunctionsHandler={searchFunctionsHandler}
            sideSummaryOpen={sideSummaryOpen}
            setSideSummaryOpen={setSideSummaryOpen}
            fileHasSummary={fileHasSummary}
          />
        ) : (
          <Spinner />
        )}
      </div>
    );
  },
);
