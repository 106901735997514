import { useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { generateRandomAlphanumeric } from 'utils/generateRandomAlphanumeric';
import { fetchMatches } from 'features/case/redux/fetchMatches';
import { useUpdateUserSettings } from 'features/common/redux/updateUserSettings';
import { useSetAISearchState } from 'features/case/redux/setAISearchState';
import { getInitials } from 'utils/getInitials';
import { useSelector } from 'react-redux';
import { selectCurrentCaseId, selectUserId, selectUserName } from 'common/selectors';
import history from 'common/history';
import { searchCenterLocation } from '../enums';
import T from 'i18n';
import { generateAnswers } from 'features/case/redux/generateAnswers';
import { askAI } from 'features/case/redux/askAI';

const useAIHandler = () => {
  const dispatch = useDispatch();
  const {
    updateAISearchMatches,
    setAISearchMatches,
    clearAISearchState,
    setAISearchAnswerChat,
    setAISearchSuggestedQuestions,
    searchMatchesInput,
    searchAnsChat,
    searchSuggestedQuestions,
  } = useSetAISearchState();
  const perfectScrollbarRef = useRef<any | null>(null);
  const { userSettings, updateUserSettings } = useUpdateUserSettings();
  const userName = useSelector(selectUserName);
  const caseId = useSelector(selectCurrentCaseId);
  const currentUserId = useSelector(selectUserId);
  const [filters, setFilters] = useState({
    location: [] as object[],
    files: [] as object[],
    phrase: '',
    searchHistoryId: '',
    question: '',
    triggerSearch: false,
  });
  const [selectedMatches, setSelectedMatches] = useState<any>([]);
  const [isNewSearch, setIsNewSearch] = useState(true);
  const [showSearchInp, setShowSearchInp] = useState(false);
  const userInitials = getInitials(userName);

  const showSuggestedQuestions =
    typeof userSettings?.aiSettings?.suggestedQuestions === 'boolean'
      ? userSettings?.aiSettings?.suggestedQuestions
      : true;
  const isAnswersMode =
    !isNewSearch &&
    (searchMatchesInput.find((itm: any) => itm.matches) ||
      (searchMatchesInput.length === 0 && searchAnsChat.length > 0));
  const matchesList = searchMatchesInput.find((itm: any) => itm.matches)?.matches || [];
  const isSummaryMode =
    !isNewSearch && searchMatchesInput?.length === 0 && searchAnsChat.length > 0;

  const matches = [
    !isSummaryMode && {
      id: 'selectedMatches',
      name: 'Selected Matches',
      type: 'matches',
      matches: selectedMatches,
    },
    !isSummaryMode && { id: 'allMatches', name: 'All Matches', type: 'matches' },
    isSummaryMode && { id: 'summary', name: 'Summary', type: 'summary' },
    ...(
      (matchesList &&
        matchesList.length > 0 &&
        matchesList.reduce((acc: any, itm: any) => {
          const existingFile = acc.find((f: any) => f.id === itm.fileId);
          if (existingFile) {
            existingFile.matches.push(itm);
            return acc;
          } else {
            return [
              ...acc,
              { id: itm.fileId, name: itm.fileName, type: 'matches', matches: [itm] },
            ];
          }
        }, [])) ||
      []
    ).sort((a: any, b: any) => b.matches.length - a.matches.length),
  ].filter(Boolean);

  const updateScrollbar = () => {
    if (perfectScrollbarRef.current) {
      const container = perfectScrollbarRef.current;
      container.scrollTop = container.scrollHeight;
    }
  };

  const createMatchesPayload = (topicId: string, inp: any, showMatchesResp: boolean): Object => {
    return {
      id: topicId,
      showMatchesResp,
      phrase: inp.phrase,
      location: inp.location,
      files: inp.files,
      user: userInitials,
      contributor: null,
      options: {
        numMatches: (userSettings.aiSettings && userSettings.aiSettings.numMatches) || 10,
        multiQuery: (userSettings.aiSettings && userSettings.aiSettings.multiQuery) || false,
        maxMarginalRelevance:
          (userSettings.aiSettings && userSettings.aiSettings.maxMarginalRelevance) || false,
        index: (userSettings.aiSettings && userSettings.aiSettings.index) || '',
      },
    };
  };

  const handleMatchesResponse = (topicId: string, res: any, inpVal?: string) => {
    if (res.router === 'topic' || res.router === 'question') {
      setFilters({
        phrase: filters.question
          ? filters.question
          : res.router === 'question'
          ? inpVal
            ? inpVal
            : filters.phrase
          : showSuggestedQuestions && res.results.length > 0
          ? 'Can you suggest 5 questions based on these extracts. List the questions without numbering them.' // Default suggested questions
          : '',
        location: res.results.length > 0 ? [matches[matches.length - 1]] : [],
        files: [],
        searchHistoryId: res.searchHistoryId,
        question: '',
        triggerSearch:
          res.router === 'question' ||
          (showSuggestedQuestions && res.router === 'topic' && res.results.length > 0),
      });
      updateAISearchMatches(topicId, { matches: res.results });
      setSelectedMatches([]);
    } else if (res.router === 'lookup') {
      setFilters((prevState: any) => ({
        ...prevState,
        phrase: '',
        location: [],
        files: res.files,
        searchHistoryId: res.searchHistoryId,
        question: '',
        triggerSearch: false,
      }));
      updateAISearchMatches(topicId, { documents: res.files });
      setSelectedMatches([]);
    } else if (res.router === 'summarize') {
      updateAISearchMatches(topicId, {
        router: res.router,
        ...(res.results.length > 0 && { showSummarizeMsg: true }),
        matches: res.results,
      });
    } else if (res.router === 'summarize_transcript') {
      updateAISearchMatches(topicId, {
        router: res.router,
        ...(res.results.length > 0 && { showSummarizeMsg: true }),
        matches: res.results,
      });
      setFilters((prevState: any) => ({
        ...prevState,
        location: res.results.length > 0 ? [matches[matches.length - 1]] : [],
      }));
    }
  };

  const processTopic = (showMatchesResp: boolean, inpVal?: string, sHisId?: string) => {
    if (isNewSearch) setIsNewSearch(false);
    const topicId = generateRandomAlphanumeric(8);
    setAISearchMatches(createMatchesPayload(topicId, filters, showMatchesResp));
    dispatch(
      fetchMatches({
        searchTerm: inpVal ? inpVal : filters.phrase,
        searchLocation: filters.location,
        files: filters.files,
        matchCentric: true,
      }),
    )
      .then((res: any) => {
        const searchHistoryId = sHisId || res.searchHistoryId;
        handleMatchesResponse(topicId, { ...res, searchHistoryId }, inpVal);
        history.push(
          history.location.pathname.replace(/\/ai-tools.*/, ``) +
            `/ai-tools${searchHistoryId ? `/${searchHistoryId}` : ''}`,
        );
      })
      .catch((err: any) => {
        clearAISearchState();
        setIsNewSearch(true);
      });
  };

  const processQuestions = (isSuggestedQuestions?: any, inpVal?: string) => {
    const elementId = generateRandomAlphanumeric(8);
    if (isSuggestedQuestions)
      setAISearchSuggestedQuestions({
        id: elementId + '-Answer',
        searching: true,
      });
    else {
      setAISearchAnswerChat([
        {
          id: elementId + '-Question',
          text: inpVal ? inpVal : filters.phrase,
          location: isSummaryMode
            ? 'Summary'
            : selectedMatches.length > 0
            ? 'Selected Matches'
            : 'All Matches',
          user: userInitials,
          contributor: null,
          typingEffect: false,
          options: {
            //To-Do remove options from here
            sourcePrompt: (userSettings.aiSettings && userSettings.aiSettings.sourcePrompt) || '',
            systemPrompt: (userSettings.aiSettings && userSettings.aiSettings.systemPrompt) || '',
            useDefaultPrompt:
              (userSettings.aiSettings && userSettings.aiSettings.useDefaultPrompt) || true,
          },
        },
        {
          id: elementId + '-AnswerReq',
          text: T.translate('case.searchingText'),
          user: 'botIcon',
          contributor: null,
          typingEffect: false,
        },
      ]);
      setTimeout(() => updateScrollbar(), 0);
    }
    dispatch(
      generateAnswers({
        matches: isSummaryMode
          ? []
          : (selectedMatches?.length > 0
              ? selectedMatches
              : searchMatchesInput.find((itm: any) => itm.matches).matches
            ).map(({ file: f, ...rest }: any) => rest),
        summary: isSummaryMode
          ? searchAnsChat.find((itm: any) => itm.command === 'summarize').text
          : [],
        searchQuery: inpVal ? inpVal : filters.phrase,
        searchHistoryId: isSuggestedQuestions ? '' : filters.searchHistoryId,
      }),
    )
      .then((res: any) => {
        const newSearchBtn = document.getElementById('new-search-button');
        //const searchContainerElem = document.getElementById('search-container');
        if (
          newSearchBtn &&
          res &&
          //searchContainerElem?.ariaLabel === res.searchHistoryId && //check if the search container is still the same
          (!res.async || (res.async && res.answer))
        ) {
          if (isSuggestedQuestions)
            setAISearchSuggestedQuestions({
              ...searchSuggestedQuestions,
              searching: false,
              questions: res.sources.length > 0 ? res.sources : null,
            });
          else
            setAISearchAnswerChat([
              {
                id: elementId + '-Answer',
                text: res.sources.length > 0 ? res.sources : T.translate('case.noAnswerFoundMsg'),
                user: 'botIcon',
                contributor: null,
                showMatches: false,
                typingEffect: true,
                matches: selectedMatches.length > 0 ? selectedMatches : searchMatchesInput.matches,
              },
            ]);
        }
        updateScrollbar();
      })
      .catch((err: any) => {
        if (!isSuggestedQuestions) {
          const newSearchBtn = document.getElementById('new-search-button');
          if (newSearchBtn) {
            setAISearchAnswerChat([
              {
                id: elementId + '-Error',
                typingEffect: false,
                text:
                  err && typeof err.body === 'string'
                    ? err.body
                    : T.translate('case.answersErrorMsg'),
                user: 'botIcon',
                contributor: null,
              },
            ]);
            updateScrollbar();
          }
        } else {
          setAISearchSuggestedQuestions({
            ...searchSuggestedQuestions,
            searching: false,
            questions: null,
          }); // Clear suggested questions
        }
      });
  };

  const askAIHandler = (command: string, fileId: string | string[], summaryType: string) => {
    if (isNewSearch) setIsNewSearch(false);
    const elementId = generateRandomAlphanumeric(8);
    setAISearchAnswerChat([
      {
        id: elementId + '-Question',
        text: `${command} ${fileId}`,
        location: selectedMatches.length > 0 ? 'Selected Matches' : 'All Matches',
        user: userInitials,
        contributor: null,
        typingEffect: false,
        options: {
          //To-Do remove options from here
          sourcePrompt: (userSettings.aiSettings && userSettings.aiSettings.sourcePrompt) || '',
          systemPrompt: (userSettings.aiSettings && userSettings.aiSettings.systemPrompt) || '',
          useDefaultPrompt:
            (userSettings.aiSettings && userSettings.aiSettings.useDefaultPrompt) || true,
        },
      },
      {
        id: elementId + '-AnswerReq',
        text: T.translate('case.searchingText'),
        user: 'botIcon',
        contributor: null,
        typingEffect: false,
      },
    ]);
    setTimeout(() => updateScrollbar(), 0);
    dispatch(askAI({ command, fileId, summaryType }))
      .then((res: any) => {
        if (!showSearchInp) setShowSearchInp(true);
        setAISearchAnswerChat([
          {
            id: elementId + '-Answer',
            text: res.summary ? res.summary : T.translate('case.noAnswerFoundMsg'),
            command: res.command,
            user: 'botIcon',
            contributor: null,
            showMatches: false,
            typingEffect: true,
            matches: selectedMatches.length > 0 ? selectedMatches : searchMatchesInput.matches,
          },
        ]);
      })
      .catch((err: any) => {
        setAISearchAnswerChat([
          {
            id: elementId + '-Error',
            typingEffect: false,
            text:
              err && typeof err.body === 'string' ? err.body : T.translate('case.answersErrorMsg'),
            user: 'botIcon',
            contributor: null,
          },
        ]);
        updateScrollbar();
      });
  };

  const saveSearchInputs = (phrase: string) => {
    const aiInputs = userSettings?.aiSearchInputs || [];
    const searchInputs = aiInputs[caseId] ? aiInputs[caseId] : [];
    let updatedSearchInputs = [];
    if (
      !!filters.location.find(
        (loc: any) =>
          loc.id === searchCenterLocation.allMatches ||
          loc.id === searchCenterLocation.selectedMatches,
      )
    ) {
      const existingTopicEntry = searchInputs.find(
        (entry: any) => entry.topic === searchMatchesInput.phrase,
      );
      const existingEntry = existingTopicEntry?.questions.find(
        (question: string) => question === phrase,
      );
      updatedSearchInputs = existingEntry
        ? aiInputs
        : {
            ...aiInputs,
            [caseId]: [
              {
                topic: existingTopicEntry ? existingTopicEntry.topic : searchMatchesInput.phrase,
                questions: [phrase, ...(existingTopicEntry?.questions || [])],
              },
              ...searchInputs.filter((entry: any) => entry.topic !== searchMatchesInput.phrase),
            ],
          };
    } else {
      const existingTopicEntry = searchInputs.find((entry: any) => entry.topic === phrase);
      updatedSearchInputs = existingTopicEntry
        ? aiInputs
        : { ...aiInputs, [caseId]: [{ topic: phrase, questions: [] }, ...searchInputs] };
    }
    updateUserSettings({
      userSettings: { ...userSettings, aiSearchInputs: updatedSearchInputs },
    });
  };

  const handleSearchHistoryResponse = (res: any, searchHistoryItmId: string) => {
    if (res && res.length === 0) {
      setAISearchAnswerChat([
        {
          isError: true,
          text: 'You don"t have access to this search history item',
        },
      ]);
      if (isNewSearch) setIsNewSearch(false);
      return;
    }
    if (isNewSearch) setIsNewSearch(false);
    if (!showSearchInp) setShowSearchInp(true);
    // clearMatches();
    // clearAnswers();
    const matchesRes = res.find((itm: any) => itm.action === 'matches');
    const originalAnswersRes = res.filter((itm: any) => itm.action === 'answers');
    const answersRes = originalAnswersRes.reduce((acc: any, itm: any) => {
      const contributor = itm.createdBy.id !== currentUserId ? itm.createdBy.name : null;
      const itemOwnerInitials = itm.createdBy ? getInitials(itm.createdBy.name) : userInitials;
      return [
        ...acc,
        {
          id: itm.id + '-Question',
          text: itm.query,
          location:
            matchesRes && matchesRes.matches.length === itm.matches.length
              ? 'All Matches'
              : 'Selected Matches',
          user: itemOwnerInitials,
          contributor,
          typingEffect: false,
          options: {
            sourcePrompt: itm.sourcePrompt,
            systemPrompt: itm.systemPrompt,
            useDefaultPrompt: itm.useDefaultPrompt,
          },
        },
        {
          id: itm.id + '-Answer',
          text:
            itm.sources?.length > 0
              ? itm.sources
              : itm.summary
              ? itm.summary
              : T.translate('case.noAnswerFoundMsg'),
          user: 'botIcon',
          command: itm.summary ? 'summarize' : '', //TO-DO use command from response need BE support itm.command,
          matches: itm.matches,
          showMatches: false,
          typingEffect: false,
          contributor: null,
        },
      ];
    }, []);
    const isSummaryModeHistory =
      (!matchesRes || matchesRes?.length === 0) && originalAnswersRes?.length > 0;
    const isAllMatches =
      matchesRes &&
      originalAnswersRes &&
      originalAnswersRes.length > 0 &&
      matchesRes.matches.length ===
        originalAnswersRes[originalAnswersRes.length - 1].matches.length;
    setFilters({
      searchHistoryId: searchHistoryItmId,
      phrase: '',
      location: [
        isSummaryModeHistory
          ? { id: 'summary', name: 'Summary', type: 'matches' }
          : { id: 'allMatches', name: 'All Matches', type: 'matches' },
      ],
      files: [],
      question: '',
      triggerSearch: false,
    });
    setSelectedMatches(
      isAllMatches || originalAnswersRes.length === 0
        ? []
        : originalAnswersRes[originalAnswersRes.length - 1].matches,
    );
    const matchesOwnerInitials = matchesRes?.createdBy
      ? getInitials(matchesRes.createdBy.name)
      : userInitials;
    const contributor =
      matchesRes && matchesRes.createdBy.id !== currentUserId ? matchesRes.createdBy.name : null;
    if (matchesRes)
      setAISearchMatches({
        phrase: matchesRes.query,
        location: matchesRes.filters.folders,
        files: matchesRes.filters?.files || [],
        user: matchesOwnerInitials,
        contributor,
        matches: matchesRes.matches,
        options: matchesRes.options,
      });
    setAISearchAnswerChat(answersRes);
    history.push(
      history.location.pathname.replace(/\/ai-tools.*/, ``) + `/ai-tools/${searchHistoryItmId}`,
    );
  };

  const handleChangeFilters = (updatedItem: any) => {
    setFilters({ ...filters, ...updatedItem });
    if (isAnswersMode && updatedItem['location']) {
      if (updatedItem['location'][0]?.id === searchCenterLocation.allMatches) {
        setSelectedMatches([]);
      } else if (updatedItem['location']?.id === searchCenterLocation.selectedMatches) {
        setSelectedMatches(updatedItem['location'].matches);
      } else {
        setSelectedMatches(updatedItem['location'].map((loc: any) => loc.matches).flat());
      }
    }
  };

  return {
    selectedMatches,
    setSelectedMatches,
    filters,
    setFilters,
    processTopic,
    isNewSearch,
    setIsNewSearch,
    processQuestions,
    askAIHandler,
    showSearchInp,
    matches,
    saveSearchInputs,
    handleSearchHistoryResponse,
    updateScrollbar,
    handleChangeFilters,
    isAnswersMode,
    perfectScrollbarRef,
    showSuggestedQuestions,
  };
};

export default useAIHandler;
