import { IconButton } from '@mui/material';
import React from 'react';
import { BatchUploadReport } from './types';
import { useFetchNotifications } from '../redux/fetchNotifications';
import T from 'i18n';
import CloseIcon from '@mui/icons-material/Close';
import { Spinner } from 'features/common';
import { UploadCompleteContent } from './UploadStatusWindow';

type MiniStatusWindowProps = {
  uploadComplete: boolean;
  uploadInProgress: boolean;
  currentBatchId: number | null;
  batchSize: number;
  caseId: string;
  batchUploadReport: BatchUploadReport | null;
  clearAll: () => any;
};

const MiniStatusWindow = ({
  uploadComplete,
  caseId,
  currentBatchId,
  uploadInProgress,
  batchUploadReport,
  batchSize,
  clearAll,
}: MiniStatusWindowProps) => {
  const { transientNotifications } = useFetchNotifications();

  const batchNotifications = transientNotifications?.filter((notification: any) => {
    return notification?.type === 'batch-processing-file' && notification?.type !== null;
  });
  return (
    <section>
      {!uploadInProgress && uploadComplete && (
        <IconButton onClick={() => clearAll()} style={{ position: 'absolute', right: 0, top: 0 }}>
          <CloseIcon style={{ height: 30, width: 30 }} />
        </IconButton>
      )}

      {uploadInProgress && !uploadComplete && (
        <div style={{ flex: 1, flexWrap: 'wrap' }}>
          <p style={{ color: '#908f90' }}>
            {T.translate('case.batchUpload.processing', {
              batchSize,
              notifications: batchNotifications?.length,
            })}
          </p>
          <span>
            <p>
              {(batchNotifications && batchNotifications[batchNotifications?.length - 1]?.title) ||
                ''}
            </p>
            <Spinner
              style={{
                fontSize: '0.4rem',
              }}
            />
          </span>
        </div>
      )}
      {uploadComplete && currentBatchId && batchUploadReport && (
        <UploadCompleteContent
          caseId={caseId}
          currentBatchId={currentBatchId}
          batchUploadReport={batchUploadReport}
        />
      )}
    </section>
  );
};

export default MiniStatusWindow;
