import initialState from './initialState';
import {
  CASE_TOGGLE_LOCAL_UPLOAD_DRAWER,
  SET_GLOBAL_FILE_METADATA,
  SET_TOGGLE_UPLOAD_DRAWER,
  SET_UPLOAD_IN_PROGRESS_PARAMS,
} from 'features/case/redux/constants';

export function setUploadDrawerOpen(isOpen, folder) {
  return { type: SET_TOGGLE_UPLOAD_DRAWER, payload: { isOpen, folder } };
}

export function setLocalUploadDrawer(isOpen, folder) {
  return { type: CASE_TOGGLE_LOCAL_UPLOAD_DRAWER, payload: isOpen };
}

export function setGlobalFileMetadata(files) {
  return { type: SET_GLOBAL_FILE_METADATA, payload: files };
}

export function setGlobalUploadInProgress(uploadInProgress, uploadComplete) {
  return {
    type: SET_UPLOAD_IN_PROGRESS_PARAMS,
    payload: { uploadInProgress, uploadComplete },
  };
}

export function reducer(state = initialState, action) {
  switch (action.type) {
    case SET_UPLOAD_IN_PROGRESS_PARAMS:
      return {
        ...state,
        uploadInProgress: action.payload.uploadInProgress,
        uploadComplete: action.payload.uploadComplete,
      };
    case SET_GLOBAL_FILE_METADATA:
      return {
        ...state,
        globalFileMetadata: action.payload.files,
      };
    case SET_TOGGLE_UPLOAD_DRAWER:
      if (state.localDrawerOpen) {
        return {
          ...state,
        };
      }
      if (action.payload.folder !== state.uploadToFolder && !state.uploadInProgress) {
        return {
          ...state,
          isUploadDrawerOpen: action.payload.isOpen,
          uploadToFolder: action.payload.folder,
        };
      } else {
        return {
          ...state,
          isUploadDrawerOpen: action.payload.isOpen,
          uploadToFolder: !state.uploadInProgress ? action.payload.folder : state.uploadToFolder,
        };
      }
    case CASE_TOGGLE_LOCAL_UPLOAD_DRAWER:
      return {
        ...state,
        localDrawerOpen: !state.localDrawerOpen,
      };
    default:
      return state;
  }
}
