import React, { useState, useEffect, useCallback } from 'react';
import T from 'i18n';
import { Button, Fab, Grid, Tooltip } from '@mui/material';
import { ChatOutlined, History, Share } from '@mui/icons-material';
import SearchInput from './SearchInput';
import { NewSearchContainer } from './NewSearchContainer';
import SearchContainer from './SearchContainer';
import { useFetchMatches } from '../redux/fetchMatches';
import { useDispatch, useSelector } from 'react-redux';
import { useGenerateAnswers } from '../redux/generateAnswers';
import { selectAiToolsFilters, selectParams } from 'common/selectors';
import SearchHistory from './SearchHistory';
import { fetchSearchHistoryItem } from '../redux/fetchSearchHistoryItem';
import { Spinner } from 'features/common';
import history from 'common/history';
import SearchInfo from './SearchInfo';
import { setAiToolsFilters } from '../redux/setAiToolsFilters';
import { getSelectedLocation } from './SearchInputLocations';
import { useSetAISearchState } from '../redux/setAISearchState';
import useAIHandler from './hooks/useAIHandler';
import SuggestedTopic from './SuggestedTopic';

const SearchCenter = () => {
  const dispatch = useDispatch();
  const { clearMatches, fetchDocumentsPending } = useFetchMatches();
  const { clearAnswers } = useGenerateAnswers();
  const { searchMatchesInput, searchAnsChat, clearAISearchState } = useSetAISearchState();

  const { file, aiSearchId } = useSelector(selectParams);
  const alreadySelectedFilters = useSelector(selectAiToolsFilters);

  const [loading, setLoading] = useState(false);
  const [showShareTooltip, setShowShareTooltip] = useState<boolean>(false);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const open = Boolean(anchorEl);

  const {
    selectedMatches,
    setSelectedMatches,
    filters,
    setFilters,
    processTopic,
    isNewSearch,
    setIsNewSearch,
    processQuestions,
    showSearchInp,
    askAIHandler,
    matches,
    saveSearchInputs,
    handleSearchHistoryResponse,
    updateScrollbar,
    handleChangeFilters,
    isAnswersMode,
    perfectScrollbarRef,
    showSuggestedQuestions,
  } = useAIHandler();

  const handleSearchItemClick = useCallback(
    (searchHistoryItmId: string) => {
      setLoading(true);
      handleSearchHistoryClose();
      clearAISearchState();
      dispatch(fetchSearchHistoryItem({ searchHistoryItmId }))
        .then((res: any) => {
          handleSearchHistoryResponse(res, searchHistoryItmId);
        })
        .finally(() => {
          setLoading(false);
        });
    },
    [dispatch, handleSearchHistoryResponse, clearAISearchState],
  );

  const handleSearch = () => {
    saveSearchInputs(filters.phrase);
    setFilters({ ...filters, phrase: '' });
    updateScrollbar();
    const containsMatches = searchMatchesInput?.find((itm: any) => itm.matches);
    const containsQues = searchAnsChat.length > 0;
    if (!isNewSearch && (containsMatches || containsQues)) {
      processQuestions();
    } else {
      processTopic(true);
    }
  };

  const handleSearchHistoryClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleSearchHistoryClose = () => {
    setAnchorEl(null);
  };

  const handleNewSearchClicked = () => {
    setIsNewSearch(true);
    history.push(history.location.pathname.replace(/\/ai-tools.*/, ``) + `/ai-tools`);
    const topicLocation = searchMatchesInput?.location || [];
    const files = searchMatchesInput?.files || [];
    clearMatches();
    clearAnswers();
    setFilters({
      searchHistoryId: '',
      phrase: '',
      location: topicLocation,
      files,
      question: '',
      triggerSearch: false,
    });
    clearAISearchState();
    setSelectedMatches([]);
    const searchInp = document.getElementById('ai-search-input');
    if (searchInp) {
      searchInp.click();
    }
  };

  const copySearchUrl = () => {
    const url = window.location.href;
    navigator.clipboard.writeText(url).then(() => {
      setShowShareTooltip(true);
    });
  };

  //TO-DO: Refactor all useEffect

  useEffect(() => {
    if (aiSearchId && !loading && searchMatchesInput?.length === 0 && searchAnsChat?.length === 0) {
      handleSearchItemClick(aiSearchId);
    }
  }, [aiSearchId, handleSearchItemClick, loading, searchAnsChat, searchMatchesInput]);

  useEffect(() => {
    if (showShareTooltip) {
      setTimeout(() => {
        setShowShareTooltip(false);
      }, 1500);
    }
    return () => {
      clearAISearchState();
    };
  }, [showShareTooltip, clearAISearchState]);

  useEffect(() => {
    if (
      showSuggestedQuestions &&
      !isNewSearch &&
      filters.triggerSearch &&
      searchMatchesInput.find((itm: any) => itm.matches)
    ) {
      const suggestedQuestion =
        'Can you suggest 5 questions based on these extracts. List the questions without numbering them.';
      setFilters({ ...filters, phrase: '', triggerSearch: false });
      processQuestions(filters.phrase === suggestedQuestion);
    }
  }, [
    isNewSearch,
    filters,
    processQuestions,
    showSuggestedQuestions,
    searchMatchesInput,
    searchAnsChat,
    setFilters,
  ]);

  useEffect(() => {
    if (alreadySelectedFilters && alreadySelectedFilters.folder) {
      setFilters({
        ...filters,
        location: [...getSelectedLocation(alreadySelectedFilters.folder)],
      });
      dispatch(setAiToolsFilters({}));
    } else if (alreadySelectedFilters && alreadySelectedFilters.files) {
      setFilters({
        ...filters,
        files: [...alreadySelectedFilters.files],
      });
      dispatch(setAiToolsFilters({}));
    }
  }, [alreadySelectedFilters, filters, dispatch, setFilters]);

  return (
    <>
      <Grid container width="100%" height="100%" padding={1}>
        <Grid
          item
          xs={12}
          sx={{ height: '2.5rem' }}
          display={'flex'}
          justifyContent={'space-between'}
        >
          <Button
            variant="outlined"
            size="small"
            startIcon={<History fontSize="small" />}
            onClick={handleSearchHistoryClick}
          >
            {T.translate('case.searchHistory')}
          </Button>
          <div>
            {!isNewSearch && (
              <>
                <Tooltip
                  placement="bottom"
                  onClose={() => setShowShareTooltip(false)}
                  open={showShareTooltip}
                  onOpen={() => setTimeout(() => setShowShareTooltip(false), 1000)}
                  disableHoverListener
                  title={T.translate('generic.copytoClipboard')}
                  arrow
                >
                  <Button
                    variant="outlined"
                    size="small"
                    sx={{ mr: '0.5rem', height: '2.5rem' }}
                    startIcon={<Share fontSize="small" />}
                    onClick={copySearchUrl}
                  >
                    {T.translate('case.shareSearchUrl')}
                  </Button>
                </Tooltip>
                <Button
                  id="new-search-button"
                  variant="outlined"
                  size="small"
                  sx={{ height: '2.5rem' }}
                  startIcon={<ChatOutlined fontSize="small" />}
                  onClick={handleNewSearchClicked}
                >
                  {T.translate('case.newSearch')}
                </Button>
              </>
            )}
          </div>
        </Grid>
        {!file && <Grid item xs={0} sm={1} md={2} lg={3} />}
        <Grid
          item
          xs={12}
          sm={file ? 12 : 10}
          md={file ? 12 : 8}
          lg={file ? 12 : 6}
          height={`calc(100% - ${isNewSearch ? '3rem' : '14rem'})`}
        >
          {loading ? (
            <Spinner />
          ) : isNewSearch ? (
            <>
              <NewSearchContainer
                handleChangeFilters={handleChangeFilters}
                handleSearch={handleSearch}
                filters={filters}
                askAIHandler={askAIHandler}
              />
            </>
          ) : (
            <SearchContainer
              selectedMatches={selectedMatches}
              scrollBarRef={perfectScrollbarRef}
              searchContainerId={filters.searchHistoryId}
              startNewSearch={handleNewSearchClicked}
              processQuestions={processQuestions}
              askAIHandler={askAIHandler}
            />
          )}
        </Grid>
        {!file && <Grid item xs={0} sm={1} md={2} lg={3} />}
        {!isNewSearch &&
          !fetchDocumentsPending &&
          searchMatchesInput &&
          (searchMatchesInput.find((itm: any) => itm.matches?.length > 0) ||
            searchMatchesInput.find((itm: any) => itm.documents?.length > 0) ||
            showSearchInp) && (
            <>
              {!file && <Grid item xs={0} sm={1} md={2} lg={3} />}
              <Grid item xs={12} sm={file ? 12 : 10} md={file ? 12 : 8} lg={file ? 12 : 6}>
                {/* <Typography
                  variant="h4"
                  sx={{ color: '#212529', fontWeight: 700, textAlign: 'center', mb: '0.5rem' }}
                >
                  {searchContainerState['searchAnsChat']?.length > 1
                    ? T.translate('case.askfollowupQuestionHeading')
                    : T.translate('case.interrogateTopicHeading')}
                </Typography> */}
                <SearchInput
                  filters={filters}
                  handleChangeFilters={handleChangeFilters}
                  handleSearch={handleSearch}
                  matches={matches}
                  isNewSearch={false}
                  containsMatches={!!searchMatchesInput.find((itm: any) => itm.matches)}
                  isAnswersMode={isAnswersMode}
                />
                {filters.files.length > 0 && (
                  <SuggestedTopic
                    filters={filters}
                    askAIHandler={askAIHandler}
                    isNewSearch={false}
                  />
                )}
              </Grid>
              {!file && (
                <Grid
                  item
                  xs={0}
                  sm={1}
                  md={2}
                  lg={3}
                  sx={{ textAlign: 'end', alignContent: 'end' }}
                >
                  <Fab size="small" sx={{ backgroundColor: 'whitesmoke', mb: '1rem' }}>
                    <SearchInfo isNewSearch={false} />
                  </Fab>
                </Grid>
              )}
            </>
          )}
        {open && anchorEl && (
          <SearchHistory
            open={open}
            anchorEl={anchorEl}
            handleSearchHistoryClose={handleSearchHistoryClose}
            handleSearchItemClick={handleSearchItemClick}
          />
        )}
      </Grid>
    </>
  );
};

export default SearchCenter;
